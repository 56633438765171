import { defineStore } from "pinia";

const useConstant = defineStore({
  id: "constant",
  state: () => ({
    davinciData: [
      {
        name: "Free_Templates",
        code: "Free_Templates",
        title: "免费合集",
        desc: "",
        imgUrl: "0.jpg",
        mp4Url: "",
        classify: "",
        lable: ["4K", "WIN", "MAC", "FREE"],
      },
      {
        name: "Batch_Copy_Tools",
        code: "Batch_Copy_Tools",
        title: "批量复制工具",
        desc: "",
        imgUrl: "20.jpg",
        mp4Url: "",
        classify: "SCRIPT",
        lable: ["4K","FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Subtitle_Tools",
        code: "Subtitle_Tools",
        title: "字幕工具",
        desc: "",
        imgUrl: "19.jpg",
        mp4Url: "",
        classify: "SCRIPT",
        lable: ["4K","FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "CallOut_Templates",
        code: "CallOut_Templates",
        title: "Call Out V2 呼出框模板V2",
        desc: "Call-out呼出框标题预字幕包，一共包含12套样式，每套分为左右两个。",
        imgUrl: "2.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Slide_Motions_Templates",
        code: "Slide_Motions_Templates",
        title: "Slide Motion 滑屏动效模板",
        desc: "",
        imgUrl: "18.jpg",
        mp4Url: "",
        classify: "EFFECTS",
        lable: ["4K","FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Split_Screen_Templates",
        code: "Split_Screen_Templates",
        title: "Split Screen 分屏模板 V2",
        desc: "分屏模板是一组包含多个分屏样式的模板合集，目前为V2版本，新增了动态关键帧调节方式，让分屏制作更加简单效率",
        imgUrl: "9.jpg",
        mp4Url: "",
        classify: "EFFECTS",
        lable: ["4K", "FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Cinematic_Titles_Template",
        code: "Cinematic_Titles_Template",
        title: "Cinematic Titles 电影标题模板",
        desc: "ViccoVlog Cinematic Titles 电影标题模板是一组包含多种类型的逐字动画效果标题模板，它不仅包含标题动画，同时也有组合的数字滚动效果以及滚动字幕，来配合在更多场景下应用",
        imgUrl: "17.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Image_Effect_Templates",
        code: "Image_Effect_Templates",
        title: "Image Effects 图像基础动画模板 V2",
        desc: "图像基础动画预设包含了十多个特效模板文件，可以协助你在达芬奇剪辑中快速为素材，合成内容添加基础动画，例如滑动，旋转，缩放，边框，阴影，甚至是制作卡点效果等等",
        imgUrl: "5.jpg",
        mp4Url: "",
        classify: "EFFECTS",
        lable: ["4K", "FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Standard_Transitions_V2",
        code: "Standard_Transitions_V2",
        title: "Standard Transitions V2 标准转场 V2",
        desc: "标准转场V1的升级版本该版本最低兼容达芬奇17，包含了变焦，自旋，马赛克，故障，柔化影集等分类40+模板，100多种样式变化，非常容易使用。",
        imgUrl: "10.jpg",
        mp4Url: "",
        classify: "TRANSITIONS",
        lable: ["4K", "FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "MG_Animation_Templates",
        code: "MG_Animation_Templates",
        title: "MG Animations 标题模板",
        desc: "MG动画预设包( MG Animations )一共包含包括标尺，箭头，烟花波纹，焦点，框选，键盘等34组Fusion MG动画预设。",
        imgUrl: "7.jpg",
        mp4Url: "",
        classify: "GENERATORS",
        lable: ["4K", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Creative_Titles_Templates",
        code: "Creative_Titles_Templates",
        title: "Creative Titles 创意标题模板",
        desc: "ViccoVlog Creative Titles 创意标题模板是一组包含多个样式类型的综合模板合集，例如时尚Modern，简约Minimal，企业Corporate等等一共30+个模板文件。",
        imgUrl: "14.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Variety_Show_Text_Templates",
        code: "Variety_Show_Text_Templates",
        title: "Variety Show Titles 综艺花字模板",
        desc: "ViccoVlog Variety Text Templates 花字模板目前包含30+模板文件分为花(图形)和字(图形+文字)两种类型",
        imgUrl: "13.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "VChart_Templates",
        code: "VChart_Templates",
        title: "Vchart Animations 图表模板",
        desc: "图表套件预设一共6种样式22个文件，同时包含了基于达芬奇17新增的前缀图标，在使用时更容易使用和查找",
        imgUrl: "11.jpg",
        mp4Url: "",
        classify: "GENERATORS",
        lable: ["4K", "FRAME", "WIN", "MAC"],
      },
      {
        name: "Wedding_Titles_Template",
        code: "Wedding_Titles_Template",
        title: "Wedding Titles 婚礼标题模板",
        desc: "ViccoVlog Wedding Titles 婚礼标题模板是一组包含多种样式的艺术效果标题类型的模板，配合花纹，图案图形或者是纯文字，来丰富婚礼神圣，优雅，温暖，柔和氛围的营造",
        imgUrl: "16.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "AERO_Glass_Transitions",
        code: "AERO_Glass_Transitions",
        title: "AERO Glass 玻璃转场模板",
        desc: "A.E.R.O玻璃转场套装预设包含4种类型一共22个转场预设文件",
        imgUrl: "1.jpg",
        mp4Url: "",
        classify: "TRANSITIONS",
        lable: ["4K", "FRAME", "WIN", "MAC"],
      },
      {
        name: "Brush_Paint_Titles_Template",
        code: "Brush_Paint_Titles_Template",
        title: "Brush Paint Titles 油彩笔刷标题模板",
        desc: "ViccoVlog Brush Paint Titles 油彩笔刷模板是一组包含多种样式的手绘笔刷类型的标题模板，同时它还有抽帧的效果与高度自由定制化的配置属性，非常适合轻松，乐趣，亲子等等场景下使用。",
        imgUrl: "15.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "SocialMedia_Animation_Templates",
        code: "SocialMedia_Animation_Templates",
        title: "Social Media Animations 社交媒体模板",
        desc: "社交媒体动画模板(Social Media Animation Templates)，是基于达芬奇17制作的原创模板预设，初始一共包含40个模板文件。分为社交媒体模板，点赞分享模板，自定义Iogo模板三类",
        imgUrl: "12.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Innovation_Titles_Templates",
        code: "Innovation_Titles_Templates",
        title: "Innovation Titles 创新标题",
        desc: "ViccoVlog Titles 标题预设是最早制作的一套标题字幕动画，包含了二+多个动画模板。",
        imgUrl: "6.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Glitch_Lower_Thirds_30FPS",
        code: "Glitch_Lower_Thirds_30FPS",
        title: "Glitch Titles 故障标题模板 30帧",
        desc: "故障效果标题预字幕包，一共包含3类型15个样式本套装为30顿率版本，适合在24/25/30顿率下使用，效果最佳",
        imgUrl: "3.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Glitch_Lower_Thirds_60FPS",
        code: "Glitch_Lower_Thirds_60FPS",
        title: "Glitch Titles 故障标题模板 60帧",
        desc: "故障效果标题预字幕包，一共包含3类型15个样式本套装为60帧率版本，适合在50/60顿率下使用，效果最佳，另有30率以及双帧率套装版本。",
        imgUrl: "4.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Rainbow_Transition",
        code: "Rainbow_Transition",
        title: "Rainbow Transitions 彩虹转场模板",
        desc: "彩虹转场 Rainbow Transitions Full Pack，包含十一套完整转场文件",
        imgUrl: "8.jpg",
        mp4Url: "",
        classify: "TRANSITIONS",
        lable: ["4K", "FRAME", "SCREEN", "WIN", "MAC"],
      },
    ],
    i18n: {
      main: {
        classify: "分类",
        orderBy: "排序",
        search: "搜索",
        all: "全部",
        more: "更多",
        home: "首页 / Home",
        templates: "模板 / Templates",
        stop: "官方店铺 / Official Shop",
        introduce: "介 绍",
        preview: "效果演示",
        teaching: "视频教学",
        buy: "购 买",
        download: "下 载",
        receive: '领 取',
        probation: '下载试用模板',
        scriptDownload: '下载试用脚本',
        downloadProbation: '下载试用',
        format: "模板格式",
        resolution: "分辨率支持",
        screen: "竖屏支持",
        frameRate: "帧率支持",
        minimumVersion: "最低软件版本",
        system: "系统支持",
        ALL: "全部",
        TITLES: "TITLES / 标题",
        TRANSITIONS: "TRANSITIONS / 转场",
        GENERATORS: "GENERATORS / 生成器",
        EFFECTS: "EFFECTS / 特效",
        SCRIPT: "SCRIPT / 脚本",
        new: "最新",
        BUY_AUTH_COPYRIGHT: "产品使用许可授权协议",
        BUY_PRICE_TITLE: "授权价格",
        BUY_TITLE: "包含以下内容",
        BUY_AUTH: "授权范围与责任",
        VISIT_MEDIA: "访问我们的社交平台",
        wSponsor: '微信赞赏',
        bSponsor: 'B站充电',
        sponsorDesc: 'Viccovlog免费合集目前已经集成了40+的模板啦!您可以扫描二维码给Vicco充电，感谢他的免费付出，也可以直接点击领取按钮。',
        sponsorReceive: '已打赏，领取合集',
        directReceive: '直接领取',
        catalogNew: '新'
      },
    },
    introduceData: {
      Free_Templates: {
        preTitle: "",
        title: "免费合集",
        imgUrl: "0.jpg",
        decs: "这是ViccoVlog开播频道以来，依据小伙伴提出的各类型建议，综合分析出应用范围最广的需求，制作的50+套达芬奇标准格式模板文件，覆盖包括转场，标题，生成器以及特效类型。支持在各类个人视频，商业视频中免费使用。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "否",
        frameRate: "任意帧率",
        minimumVersion:
          "DaVinci Resolve 17.2.1 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      Batch_Copy_Tools: {
        preTitle: "Batch Copy Tools",
        title: "批量复制工具",
        imgUrl: "20.jpg",
        decs: "这是ViccoVlog第二个脚本工具。使用原生API编写，只需要安装达芬奇软件即可。它弥补了达芬奇批量复制功能的不足，扩展了达芬奇对Fusion片段或者模版中属性进行批量复制粘贴的功能。简单易用，是提高工作效率的必备工具之一",
        format: "Lua",
        resolution: "任意分辨率",
        screen: "是",
        frameRate: "任意帧率",
        minimumVersion:
          "DaVinci Resolve 17.2.1",
        system: "Windows10 / Mac / Linux",
      },
      Subtitle_Tools: {
        preTitle: "Subtitle Tools",
        title: "字幕工具",
        imgUrl: "19.jpg",
        decs: "这是ViccoVlog第一个脚本工具。使用Davinci Resolve原生API编写，只需要安装Davinci Resolve即可，无需安装其他环境。可以实现SRT外挂字幕，按照Fusion标题预设，一键生成Fusion标题字幕，同时也支持自定义的Fusion标题模板。是一款字幕制作的实用工具。",
        format: "Lua",
        resolution: "任意分辨率",
        screen: "是",
        frameRate: "任意帧率",
        minimumVersion:
          "DaVinci Resolve 17.2.1",
        system: "Windows10 / Mac / Linux",
      },
      Slide_Motions_Templates: {
        preTitle: "Slide Motion",
        title: "滑屏动效模板",
        imgUrl: "18.jpg",
        decs: "这是一套包含了50+滑动多屏效果的套装模版，同时包含了特效和转场，其中也有竖屏的专属样式，每一个模板都是精心制作，它们可以为你在制作多段素材拼接与转换时提供更多创意和可能性。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "是（仅支持标准9:16比例竖屏）",
        frameRate: "任意帧率",
        minimumVersion:
          "DaVinci Resolve 17.2.1 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      AERO_Glass_Transitions: {
        preTitle: "AERO Glass",
        title: "玻璃转场模板",
        imgUrl: "1.jpg",
        decs: "专门为需要优雅，高级感的玻璃效果量身定制的玻璃转场，让午夜或是通透的画面呈现更加动感的效果，每一个方向或者形状的玻璃转场效果都自带颜色与高斯模糊调整，让画面匹配度更佳合适。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "否",
        frameRate: "任意帧率",
        minimumVersion:
          "DaVinci Resolve 16.2 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      Brush_Paint_Titles_Template: {
        preTitle: "Brush Paint Titles",
        title: "油彩笔刷标题模板",
        imgUrl: "15.jpg",
        decs: "我想在很多富有创意类型的影片中，这类模板会经常出现。因为它采用了非常独特的抽帧展示方式，让动画模拟出逐格动画的效果，非常有意思，我们在视频制作中也经常会应用到这类拍摄效果，如果同时再配合这样的标题就再合适不过了。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "是（仅支持标准9:16比例竖屏）",
        frameRate: "任意帧率",
        minimumVersion:
          "DaVinci Resolve 17.2.1 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      CallOut_Templates: {
        preTitle: "Call Out V2",
        title: "呼出框模板V2",
        imgUrl: "2.jpg",
        decs: "全新的V2版本呼出框可以更加容易的输入多行标题，更加自由的控制折线样式、焦点样式以及指向方向。动画也全面进化，可以无极调节出入场动画速度，并一键关闭它们。同时在Fusion片段中嵌套多个呼出框也可以一键设置动画偏移，非常简单方便。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "是（仅支持标准9:16比例竖屏）",
        frameRate: "任意帧率",
        minimumVersion:
          "DaVinci Resolve 17.2.1 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      Cinematic_Titles_Template: {
        preTitle: "ViccoVlog Cinematic Titles",
        title: "电影标题模板",
        imgUrl: "17.jpg",
        decs: "简约，富有电影感的一套标题，它独具创新的使用了多层遮罩，在达芬奇中更轻松的实现了同一段文字可以套用不同颜色的功能。全部模板都使用了逐字动画，同时可以独家分别控制入场和出场的逐字动画方式，让模板可以在剪辑页面中更加自由的实现效果定制，制作出属于设计师独特的标题模板。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "是（仅支持标准9:16比例竖屏）",
        frameRate: "任意帧率",
        minimumVersion:
          "DaVinci Resolve 17.2.1 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      Creative_Titles_Templates: {
        preTitle: "Creative Titles",
        title: "创意标题模板",
        imgUrl: "14.jpg",
        decs: "这套内容展示出的是商业化的样式，同时配合简约的逐字动画效果，是在各类视频与影片中都能应用到的标题动画效果，也是Vicco本人最常用的一套标题效果之一。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "是（仅支持标准9:16比例竖屏）",
        frameRate: "23帧~60帧",
        minimumVersion:
          "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      Glitch_Lower_Thirds_30FPS: {
        preTitle: "Glitch Titles",
        title: "故障标题模板 30帧",
        imgUrl: "3.jpg",
        decs: "这是一套赛博朋克风格的标题模板，采用了线框，闪烁与文字结合的方式，展示出故障文字的效果，如果你需要制作游戏，科幻类型的影片可以使用这套模板，它可以作为标题居中使用，也可以在画面角落作为角标使用。支持文字，Gltich形状颜色的任意调整。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "是（仅支持标准9:16比例竖屏）",
        frameRate: "23帧~30帧",
        minimumVersion:
          "DaVinci Resolve 16.2 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      Glitch_Lower_Thirds_60FPS: {
        preTitle: "Glitch Titles",
        title: "故障标题模板 60帧",
        imgUrl: "4.jpg",
        decs: "这是一套赛博朋克风格的标题模板，采用了线框，闪烁与文字结合的方式，展示出故障文字的效果，如果你需要制作游戏，科幻类型的影片可以使用这套模板，它可以作为标题居中使用，也可以在画面角落作为角标使用。支持文字，Gltich形状颜色的任意调整。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "是（仅支持标准9:16比例竖屏）",
        frameRate: "50帧~60帧",
        minimumVersion:
          "DaVinci Resolve 16.2 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      Image_Effect_Templates: {
        preTitle: "Image Effects",
        title: "图像基础动画模板 V2",
        imgUrl: "5.jpg",
        decs: "这是一套完全为达芬奇量身定制的基础动画扩展包，因为达芬奇本身在剪辑页制作一些简单动效的复杂度过高，所以可以利用这套内容非常容易的制作出变焦缩放，任意角度的滑动，抖动，一键添加各类型阴影，一键增加形状遮罩或者描边，当然，我们也可以同时套用多个效果来复合使用。因此，使用这套内容就可以更加轻松的在剪辑页面完成一些简单动效了。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "是（仅支持标准9:16比例竖屏）",
        frameRate: "任意帧率",
        minimumVersion:
          "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      Innovation_Titles_Templates: {
        preTitle: "Innovation Titles",
        title: "创新标题",
        imgUrl: "6.jpg",
        decs: "这是ViccoVlog原创制作的第一套基于达芬奇Fusion的标题模板，使用了关键帧动画与遮罩，使用色块，线条与文字进行搭配，形成流动性的文字动画效果。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "是（仅支持标准9:16比例竖屏）",
        frameRate: "23帧~60帧",
        minimumVersion:
          "DaVinci Resolve 16.2 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      MG_Animation_Templates: {
        preTitle: "MG Animations",
        title: "动画模板",
        imgUrl: "7.jpg",
        decs: "这是一套功能非常全面的MG动画套装模板，它集成诸有意思且常用的小动画，例如形状，标注，标尺，流动背景，键盘效果等等，每一个都可以任意自定义位置，大小，颜色等等，我们只需要拖拽到时间线上，调节一些参数即可完成设置，非常的简单。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "是（仅支持标准9:16比例竖屏）",
        frameRate: "23帧~60帧",
        minimumVersion:
          "DaVinci Resolve 16.2 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      Rainbow_Transition: {
        preTitle: "Rainbow Transitions",
        title: "彩虹转场模板",
        imgUrl: "8.jpg",
        decs: "这是专为活泼，欢快主题设计的一套转场模板，它使用了各种角度或者形状遮罩来模拟彩虹的样式。如果你需要在欢快主题的影片中制作一些转场效果，那这个最适合不过了。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "是（仅支持标准9:16比例竖屏）",
        frameRate: "任意帧率",
        minimumVersion:
          "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      SocialMedia_Animation_Templates: {
        preTitle: "Social Media Animations",
        title: "社交媒体模板",
        imgUrl: "12.jpg",
        decs: "如果你希望在自媒体视频中插入一些个性化的社交媒体图像，那这套模板就再适合不过了，它集成了众多主流社交媒体的LOGO图形，每一个LOGO都有单独的动画设计，非常好看。我们可以用它来为自己制作关注订阅动画，也可以制作一些留言动画等等。当然，如果你希望能自定义LOGO图片，这里也提供了自定义模板供你使用。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "是（仅支持标准9:16比例竖屏）",
        frameRate: "任意帧率",
        minimumVersion:
          "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      Split_Screen_Templates: {
        preTitle: "Split Screen",
        title: "分屏模板 V2",
        imgUrl: "9.jpg",
        decs: "制作分屏效果的最佳利器，同时利用达芬奇特效的独特功能，制作了独一无二的模板套用方式，让多素材应用分屏更加容易。在这套模板中，集成了常用的各类型分屏效果，同时它支持无极速度动画调节以及出入场自由设置，在出入场动画中也支持滑动和变焦两种方式的自由切换，非常方便。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "是（仅支持标准9:16比例竖屏）",
        frameRate: "任意帧率",
        minimumVersion:
          "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      Standard_Transitions_V2: {
        preTitle: "Standard Transitions V2",
        title: "标准转场 V2",
        imgUrl: "10.jpg",
        decs: "ViccoVlog目前应用范围最广，效果类别最多的转场包，它提供了几乎所有常用的转场效果，是达芬奇官方转场的最完美的内容扩展，属于实用性非常强的基础转场模板，它包括变焦，滑动，光斑，遮罩等十几个类别的常用转场效果，足以满足对基础转场效果的需要。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "是（仅支持标准9:16比例竖屏）",
        frameRate: "任意帧率",
        minimumVersion:
          "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      Variety_Show_Text_Templates: {
        preTitle: "Variety Show Titles",
        title: "综艺花字模板",
        imgUrl: "13.jpg",
        decs: "这是达芬奇模板世界中少有的原创花字模板，在我们平时制作短视频或者自媒体视频中，这类型的标题文字都缺不了，是一套应用范围超广的内容。花字中使用的图形均来自于手绘图形，矢量图形可以在各类分辨率下均保证超精细的显示效果，文字与颜色都可以自由自定义。出入场动画也采用了无级变速方式，调节更加精细和自由。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "是（仅支持标准9:16比例竖屏）",
        frameRate: "任意帧率",
        minimumVersion:
          "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      VChart_Templates: {
        preTitle: "Vchart Animations",
        title: "图表模板",
        imgUrl: "11.jpg",
        decs: "这是ViccoVlog独家创作的一套在达芬奇环境下使用的图表类型模板，我们可以很轻松的通过多轨道叠加的方式实现柱状图动画演示，也可以制作折线图，饼状图，甚至是非常漂亮的雷达图。当然还包括各类进度条，倒计时等等众多的图表效果。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "否",
        frameRate: "任意帧率",
        minimumVersion:
          "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      Wedding_Titles_Template: {
        preTitle: "Wedding Titles",
        title: "婚礼标题模板",
        imgUrl: "16.jpg",
        decs: "优雅，端庄，简约是这套模板设计的初衷，它一半采用了具有流畅手绘矢量图形配合文字的展示方式，一半则只使用了缓动效果的逐字文字动画，更加简约，大气。这套模板的复杂点在于，所有的图形，哪怕一片树叶都有独立的动画方式，因此整体的动画效果会显得更加的精细优雅，如果你在制作婚礼类型的影片，那这套模板一定会提供更多的艺术氛围。",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "是（仅支持标准9:16比例竖屏）",
        frameRate: "任意帧率",
        minimumVersion:
          "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
    },
    buyData: {
      Free_Templates: {
        preTitle: "",
        title: "免费合集",
        imgUrl: "0.jpg",
        tryUrl: '',
        more:   [
          {
            title: "免费合集",
            imgUrl: "1.jpg",
            buyUrl: "https://pan.baidu.com/s/1hKg1XgLGOSqLgwlhj6MpWQ?pwd=4w5z",
            price: "0",
          },
        ]
      },
      Batch_Copy_Tools: {
        preTitle: "",
        title: "批量复制工具",
        imgUrl: "20.jpg",
        tryUrl:"https://pan.baidu.com/s/1MlGGwIrG-3nds6Q0s5vHNg?pwd=wn4d",
        more:   [
          {
            title: "字幕工具",
            imgUrl: "20.jpg",
            buyUrl: "https://item.taobao.com/item.htm?id=819521096602",
            price: "19.9",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=819521096602",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3698072899798827181&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/66a467f93a66500001f3a1db?t=1730453607613&xsec_token=ABmZJCP-Puwwl8ltCnv_GdBjEFVrrDia3FNORyrZD3eBY%3D&xsec_source=app_arkselfshare"
          },
        ]
      },
      Subtitle_Tools: {
        preTitle: "",
        title: "字幕工具",
        imgUrl: "19.jpg",
        tryUrl:"https://pan.baidu.com/s/1ZdOPayByyOZg8UZxdSW4pQ?pwd=85jj",
        more:   [
          {
            title: "字幕工具",
            imgUrl: "18.jpg",
            buyUrl: "https://item.taobao.com/item.htm?id=793884476373",
            price: "14.9",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=793884476373",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3683086764643320033&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/663c34ea3a727000018c024c?t=1730453607614&xsec_token=ABlB1bSVB8pvC0if0URXNiNhN7WTNQqyg7lKRcKH7FXf8%3D&xsec_source=app_arkselfshare"
          },
        ]
      },
      Slide_Motions_Templates: {
        preTitle: "",
        title: "滑屏动效模板",
        imgUrl: "18.jpg",
        tryUrl:"https://pan.baidu.com/s/1O2vSLyIA0fZvBWHpTaPUhg?pwd=6ii7",
        more:   [
          {
            title: "滑屏动效模板",
            imgUrl: "18.jpg",
            buyUrl: "https://item.taobao.com/item.htm?id=784614406488&spm=a1z10.1-c-s.w4004-23621573054.37.23b813b3ZCTh3y",
            price: "39.9",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784614406488&spm=a1z10.1-c-s.w4004-23621573054.37.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3679178995200557164&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/661e25f519608c00013f70cb?t=1730453607615&xsec_token=ABGoGhoZUlTujX12T22Gpz10KnFMRzHHHEs6oBXBpMLkM%3D&xsec_source=app_arkselfshare"

          },
          {
            title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7（不包含脚本工具）",
            imgUrl: "7.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            price: "316",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661509034965621041&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c472ee07642600010b36ca?t=1730606336233&xsec_token=ABGPrCwWYInDmIcApgAeK447qvMdQ2WGAw1-fJ4H8cdTc%3D&xsec_source=app_arkselfshare"
          },
        ]
      },
      AERO_Glass_Transitions: {
        preTitle: "AERO Glass",
        title: "玻璃转场模板",
        imgUrl: "1.jpg",
        tryUrl:"https://pan.baidu.com/s/1i0i6uOWrITGSiRbFSsmkJA?pwd=9t9f",
        more: [
          {
            title: "ViccoVlog 达芬奇 AERO玻璃转场套装效果预设模板",
            imgUrl: "1.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.1-c-s.w4004-23621573054.19.6aee13b3zuH5s8&id=643032210658",
            price: "12.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.1-c-s.w4004-23621573054.19.6aee13b3zuH5s8&id=643032210658",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661890024384950865&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c46312862c6800014349cf?t=1730454083589&xsec_token=ABNw3oAOnDDP3yccs1TIgabT38VujMT2Xcwh36SVLXVok%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7（不包含脚本工具）",
            imgUrl: "7.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            price: "316",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661509034965621041&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c472ee07642600010b36ca?t=1730606336233&xsec_token=ABGPrCwWYInDmIcApgAeK447qvMdQ2WGAw1-fJ4H8cdTc%3D&xsec_source=app_arkselfshare"
          },
          // {
          //   title: "ViccoVlog 达芬奇原创转场模板合集 V1",
          //   imgUrl: "4.jpg",
          //   buyUrl:
          //     "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.51.3d9161ebqsyGQ9&id=732309176123",
          //   price: "40.90",
          // },
        ]
      },
      Brush_Paint_Titles_Template: {
        preTitle: "Brush Paint Titles",
        title: "油彩笔刷标题模板",
        imgUrl: "15.jpg",
        tryUrl:"https://pan.baidu.com/s/1NLzt9E_mUZ2foa_RWOYXZA?pwd=ixs4",
        more: [
          {
            title: "ViccoVlog 达芬奇原创 油彩笔刷标题模板 Brush Paint Titles",
            imgUrl: "2.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.45.3d9161ebqsyGQ9&id=681455887219",
            price: "19.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.45.3d9161ebqsyGQ9&id=681455887219",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661895792525973373&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c463bcb752d60001c142e1?t=1730454083589&xsec_token=ABg_E264vU-9lQgnNCNNYB29H3launRcsfi0WzFsWA6yM%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7（不包含脚本工具）",
            imgUrl: "7.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            price: "316",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661509034965621041&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c472ee07642600010b36ca?t=1730606336233&xsec_token=ABGPrCwWYInDmIcApgAeK447qvMdQ2WGAw1-fJ4H8cdTc%3D&xsec_source=app_arkselfshare"
          },
          // {
          //   title: "ViccoVlog 达芬奇原创标题模板合集 V1",
          //   imgUrl: "5.jpg",
          //   buyUrl:
          //     "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   price: "179",
          //   taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   douYinUrl: "",
          //   xiaoHongShuUrl: ""
          // },
        ]
      } ,
      CallOut_Templates: {
        preTitle: "Call Out",
        title: "呼出框模板",
        imgUrl: "2.jpg",
        tryUrl:"https://pan.baidu.com/s/11ajl4BIBMa2mgzcl4e8zCw?pwd=cdxz",
        more: [
          {
            title: "ViccoVlog 达芬奇 Call-out呼出框标题字幕预设包",
            imgUrl: "2.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?abbucket=14&id=795588112553&ns=1&priceTId=2147808d17156699418444157e68fb&spm=a21n57.1.item.29.1728523cEyXi7l",
            price: "19.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?abbucket=14&id=795588112553&ns=1&priceTId=2147808d17156699418444157e68fb&spm=a21n57.1.item.29.1728523cEyXi7l",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661525779046601654&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c431873bdecc00016b893b?t=1730454226356&xsec_token=ABzpzlebQmDljSku3JMEon4OdhN7T0lfI-rWEf0NAL49I%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7（不包含脚本工具）",
            imgUrl: "7.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            price: "316",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661509034965621041&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c472ee07642600010b36ca?t=1730606336233&xsec_token=ABGPrCwWYInDmIcApgAeK447qvMdQ2WGAw1-fJ4H8cdTc%3D&xsec_source=app_arkselfshare"
          },
          // {
          //   title: "ViccoVlog 达芬奇原创标题模板合集 V1",
          //   imgUrl: "5.jpg",
          //   buyUrl:
          //     "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   price: "179",
          //   taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   douYinUrl: "",
          //   xiaoHongShuUrl: ""
          // },
        ]
      },
      Cinematic_Titles_Template: {
        preTitle: "ViccoVlog Cinematic Titles",
        title: "电影标题模板",
        imgUrl: "17.jpg",
        tryUrl:"https://pan.baidu.com/s/1YPiGdzKeN7kZbuE4g6oz8A?pwd=kitb",
        more: [
          {
            title: "ViccoVlog 达芬奇原创 电影标题模板 Cinematic Titles",
            imgUrl: "2.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.49.3d9161ebqsyGQ9&id=725784439357",
            price: "34.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.49.3d9161ebqsyGQ9&id=725784439357",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661397260605984173&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c42f98f508900001a3eaa2?t=1730454226356&xsec_token=ABo5Kyxtaqw2eUia5pv5mkrciJvZ3Duy21H9eoQGvbfiQ%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7（不包含脚本工具）",
            imgUrl: "7.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            price: "316",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661509034965621041&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c472ee07642600010b36ca?t=1730606336233&xsec_token=ABGPrCwWYInDmIcApgAeK447qvMdQ2WGAw1-fJ4H8cdTc%3D&xsec_source=app_arkselfshare"
          },
          // {
          //   title: "ViccoVlog 达芬奇原创标题模板合集 V1",
          //   imgUrl: "5.jpg",
          //   buyUrl:
          //     "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   price: "179",
          //   taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   douYinUrl: "",
          //   xiaoHongShuUrl: ""
          // },
        ]
      },
      Creative_Titles_Templates: {
        preTitle: "Creative Titles",
        title: "创意标题模板",
        imgUrl: "14.jpg",
        tryUrl:"https://pan.baidu.com/s/1XwFzTgKRX7hV7dYCcLvpoQ?pwd=qn7n",
        more: [
          {
            title: "ViccoVlog 达芬奇原创 创意标题模板 Creative Titles Template",
            imgUrl: "2.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.41.3d9161ebqsyGQ9&id=668738475485",
            price: "28.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.41.3d9161ebqsyGQ9&id=668738475485",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661521112464027645&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c44f61f508900001a41afc?t=1730454226355&xsec_token=ABo5Kyxtaqw2eUia5pv5mkrbIkdV-N7ST4o2Vjh4-4x5E%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7（不包含脚本工具）",
            imgUrl: "7.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            price: "316",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661509034965621041&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c472ee07642600010b36ca?t=1730606336233&xsec_token=ABGPrCwWYInDmIcApgAeK447qvMdQ2WGAw1-fJ4H8cdTc%3D&xsec_source=app_arkselfshare"
          },
          // {
          //   title: "ViccoVlog 达芬奇原创标题模板合集 V1",
          //   imgUrl: "5.jpg",
          //   buyUrl:
          //     "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   price: "179",
          //   taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   douYinUrl: "",
          //   xiaoHongShuUrl: ""
          // },
        ]
      },
      Glitch_Lower_Thirds_30FPS: {
        preTitle: "Glitch Titles",
        title: "故障标题模板 30帧",
        imgUrl: "3.jpg",
        tryUrl:"https://pan.baidu.com/s/1x8D637ZZZrQIVc2q-dcrtw?pwd=x24d",
        more: [
          {
            title: "ViccoVlog 达芬奇 Glitch故障效果标题字幕预设包 30帧率版本",
            imgUrl: "2.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.25.3d9161ebqsyGQ9&id=643398639704",
            price: "12.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.25.3d9161ebqsyGQ9&id=643398639704",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661883876214746004&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c471679fb5f80001ad3e22?t=1730454224624&xsec_token=AB0b71bU0s3d1iYazDxgCxkaDf29ueh3dRzahoNR4edhg%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7（不包含脚本工具）",
            imgUrl: "7.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            price: "316",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661509034965621041&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c472ee07642600010b36ca?t=1730606336233&xsec_token=ABGPrCwWYInDmIcApgAeK447qvMdQ2WGAw1-fJ4H8cdTc%3D&xsec_source=app_arkselfshare"
          },
          // {
          //   title: "ViccoVlog 达芬奇原创标题模板合集 V1",
          //   imgUrl: "5.jpg",
          //   buyUrl:
          //     "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   price: "179",
          //   taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   douYinUrl: "",
          //   xiaoHongShuUrl: ""
          // },
          {
            title: "ViccoVlog 达芬奇 Glitch故障效果标题字幕预设包 全帧率版本",
            imgUrl: "3.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.19.3d9161ebqsyGQ9&id=643058486775",
            price: "20.90",
          },
        ]
      },
      Glitch_Lower_Thirds_60FPS: {
        preTitle: "Glitch Titles",
        title: "故障标题模板 60帧",
        imgUrl: "4.jpg",
        tryUrl:"https://pan.baidu.com/s/1x8D637ZZZrQIVc2q-dcrtw?pwd=x24d",
        more:  [
          {
            title: "ViccoVlog 达芬奇 Glitch故障效果标题字幕预设包 60帧率版本",
            imgUrl: "2.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.11.3d9161ebqsyGQ9&id=642328480153",
            price: "12.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.11.3d9161ebqsyGQ9&id=642328480153",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661883876214746004&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c471679fb5f80001ad3e22?t=1730454224624&xsec_token=AB0b71bU0s3d1iYazDxgCxkaDf29ueh3dRzahoNR4edhg%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7（不包含脚本工具）",
            imgUrl: "7.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            price: "316",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661509034965621041&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c472ee07642600010b36ca?t=1730606336233&xsec_token=ABGPrCwWYInDmIcApgAeK447qvMdQ2WGAw1-fJ4H8cdTc%3D&xsec_source=app_arkselfshare"
          },
          // {
          //   title: "ViccoVlog 达芬奇原创标题模板合集 V1",
          //   imgUrl: "5.jpg",
          //   buyUrl:
          //     "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   price: "179",
          //   taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   douYinUrl: "",
          //   xiaoHongShuUrl: ""
          // },
          {
            title: "ViccoVlog 达芬奇 Glitch故障效果标题字幕预设包 全帧率版本",
            imgUrl: "3.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.19.3d9161ebqsyGQ9&id=643058486775",
            price: "20.90",
          },
        ]
      },
      Image_Effect_Templates: {
        preTitle: "Image Effects",
        title: "图像基础动画模板 V2",
        imgUrl: "5.jpg",
        tryUrl:"https://pan.baidu.com/s/1-pgT4cWL-QEWhGpm5Wv4Ow?pwd=p58v",
        more:  [
          {
            title: "ViccoVlog 达芬奇 图像基础动画 V2 Image Effects 预设模板套件",
            imgUrl: "2.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.23.3d9161ebqsyGQ9&id=643368359726",
            price: "26.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.23.3d9161ebqsyGQ9&id=643368359726",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661375029930757499&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c44700fe1f3d0001d6b85e?t=1730454224622&xsec_token=ABX-gWq_Hnhzdh2I037dKACj5vIgnNmd4KV_SNgwq5P5A%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7（不包含脚本工具）",
            imgUrl: "7.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            price: "316",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661509034965621041&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c472ee07642600010b36ca?t=1730606336233&xsec_token=ABGPrCwWYInDmIcApgAeK447qvMdQ2WGAw1-fJ4H8cdTc%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “萌新四件套” 模板优惠折扣套装",
            imgUrl: "1.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.43.3d9161ebqsyGQ9&id=680865926077",
            price: "69.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.43.3d9161ebqsyGQ9&id=680865926077",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661506331300477333&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c47299729b0e0001fe25b0?t=1730606336233&xsec_token=ABdsk-YP38akFZOxiIrrzn3BgrqSQtbBoancFHqqMDlAQ%3D&xsec_source=app_arkselfshare"
          },
        ]
      },
      Innovation_Titles_Templates: {
        preTitle: "Innovation Titles",
        title: "创新标题",
        imgUrl: "6.jpg",
        tryUrl:"https://pan.baidu.com/s/1an1yBCp-8e2mdoV_gwDVIQ?pwd=avfq",
        more: [
          {
            title: "ViccoVlog 达芬奇原创 标题动画模板 Innovation Titles",
            imgUrl: "2.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.31.3d9161ebqsyGQ9&id=650810324119",
            price: "12.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.31.3d9161ebqsyGQ9&id=650810324119",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661519791837032276&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c465bef508900001a43dee?t=1730454811834&xsec_token=ABo5Kyxtaqw2eUia5pv5mkrZeux5tZeSx_w16BltwM3eo%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7（不包含脚本工具）",
            imgUrl: "7.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            price: "316",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661509034965621041&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c472ee07642600010b36ca?t=1730606336233&xsec_token=ABGPrCwWYInDmIcApgAeK447qvMdQ2WGAw1-fJ4H8cdTc%3D&xsec_source=app_arkselfshare"
          },
          // {
          //   title: "ViccoVlog 达芬奇原创标题模板合集 V1",
          //   imgUrl: "5.jpg",
          //   buyUrl:
          //     "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   price: "179",
          //   taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   douYinUrl: "",
          //   xiaoHongShuUrl: ""
          // },
        ]
      },
      MG_Animation_Templates: {
        preTitle: "MG Animations",
        title: "标题模板",
        imgUrl: "7.jpg",
        tryUrl:"https://pan.baidu.com/s/1k3F1FBA_7UIX6xcbLRmatQ?pwd=as9i",
        more: [
          {
            title: "ViccoVlog 达芬奇 MG动画预设模板套件",
            imgUrl: "2.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.9.3d9161ebqsyGQ9&id=642310680337",
            price: "28.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.9.3d9161ebqsyGQ9&id=642310680337",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661240543792475043&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c44dbc9fb5f80001ad0219?t=1730454588963&xsec_token=AB0b71bU0s3d1iYazDxgCxkb3sCMoknWidqEr9VHibmEE%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7（不包含脚本工具）",
            imgUrl: "7.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            price: "316",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661509034965621041&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c472ee07642600010b36ca?t=1730606336233&xsec_token=ABGPrCwWYInDmIcApgAeK447qvMdQ2WGAw1-fJ4H8cdTc%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “萌新四件套” 模板优惠折扣套装",
            imgUrl: "1.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.43.3d9161ebqsyGQ9&id=680865926077",
            price: "69.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.43.3d9161ebqsyGQ9&id=680865926077",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661506331300477333&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c47299729b0e0001fe25b0?t=1730606336233&xsec_token=ABdsk-YP38akFZOxiIrrzn3BgrqSQtbBoancFHqqMDlAQ%3D&xsec_source=app_arkselfshare"
          },
        ]
      } ,
      Rainbow_Transition: {
        preTitle: "Rainbow Transitions",
        title: "彩虹转场模板",
        imgUrl: "8.jpg",
        tryUrl: "https://pan.baidu.com/s/1BiXTTTXIOAD26JixEtT8xA?pwd=jcep",
        more: [
          {
            title: "ViccoVlog 达芬奇原创彩虹转场Rainbow Transitions 预设模板套件",
            imgUrl: "2.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.21.3d9161ebqsyGQ9&id=643348583218",
            price: "12.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.21.3d9161ebqsyGQ9&id=643348583218",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661423975445200600&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c471c9f508900001a45550?t=1730454809973"
          },
          {
            title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7（不包含脚本工具）",
            imgUrl: "7.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            price: "316",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661509034965621041&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c472ee07642600010b36ca?t=1730606336233&xsec_token=ABGPrCwWYInDmIcApgAeK447qvMdQ2WGAw1-fJ4H8cdTc%3D&xsec_source=app_arkselfshare"
          },
          // {
          //   title: "ViccoVlog 达芬奇原创转场模板合集 V1",
          //   imgUrl: "4.jpg",
          //   buyUrl:
          //     "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.51.3d9161ebqsyGQ9&id=732309176123",
          //   price: "40.90",
          // },
        ]
      } ,
      SocialMedia_Animation_Templates: {
        preTitle: "Social Media Animations",
        title: "社交媒体模板",
        imgUrl: "12.jpg",
        tryUrl:"https://pan.baidu.com/s/1I88IKwik2z69T6nSvZEs_w?pwd=571p",
        more: [
          {
            title: "ViccoVlog 达芬奇原创 社交媒体动画模板 SocialMedia Templates",
            imgUrl: "2.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.29.3d9161ebqsyGQ9&id=650390345233",
            price: "28.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.29.3d9161ebqsyGQ9&id=650390345233",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661882579075896992&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c46424862c680001434be0?t=1730454811834&xsec_token=ABNw3oAOnDDP3yccs1TIgabbRjik1w0B7G1ixHsAY6q38%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7（不包含脚本工具）",
            imgUrl: "7.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            price: "316",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661509034965621041&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c472ee07642600010b36ca?t=1730606336233&xsec_token=ABGPrCwWYInDmIcApgAeK447qvMdQ2WGAw1-fJ4H8cdTc%3D&xsec_source=app_arkselfshare"
          },
          // {
          //   title: "ViccoVlog 达芬奇原创标题模板合集 V1",
          //   imgUrl: "5.jpg",
          //   buyUrl:
          //     "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   price: "179",
          //   taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   douYinUrl: "",
          //   xiaoHongShuUrl: ""
          // },
        ]
      } ,
      Split_Screen_Templates: {
        preTitle: "Split Screen",
        title: "分屏模板 V2",
        imgUrl: "9.jpg",
        tryUrl:"https://pan.baidu.com/s/1K5fcBpUN9Y-tENzF3ZKZSg?pwd=i8n7",
        more: [
          {
            title: "ViccoVlog 达芬奇原创分屏模板Split Screen V2 模板套件",
            imgUrl: "2.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.15.3d9161ebqsyGQ9&id=643024470223",
            price: "28.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.15.3d9161ebqsyGQ9&id=643024470223",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661169142066733244&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c4442d9fb5f80001acf2f0?t=1730454809972&xsec_token=AB0b71bU0s3d1iYazDxgCxkYQvqrVWCI6-lyje6gml9gs%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7（不包含脚本工具）",
            imgUrl: "7.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            price: "316",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661509034965621041&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c472ee07642600010b36ca?t=1730606336233&xsec_token=ABGPrCwWYInDmIcApgAeK447qvMdQ2WGAw1-fJ4H8cdTc%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “萌新四件套” 模板优惠折扣套装",
            imgUrl: "1.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.43.3d9161ebqsyGQ9&id=680865926077",
            price: "69.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.43.3d9161ebqsyGQ9&id=680865926077",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661506331300477333&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c47299729b0e0001fe25b0?t=1730606336233&xsec_token=ABdsk-YP38akFZOxiIrrzn3BgrqSQtbBoancFHqqMDlAQ%3D&xsec_source=app_arkselfshare"
          },
        ]
      } ,
      Standard_Transitions_V2: {
        preTitle: "Standard Transitions V2",
        title: "标准转场 V2",
        imgUrl: "10.jpg",
        tryUrl: "https://pan.baidu.com/s/1bdbFbShJ3AYJPqE5Ih6Jdg?pwd=urxj",
        more: [
          {
            title: "ViccoVlog 达芬奇原创 标准转场V2 升级版",
            imgUrl: "2.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.33.3d9161ebqsyGQ9&id=652559211645",
            price: "24.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.33.3d9161ebqsyGQ9&id=652559211645",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661200164657495295&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c44a5d3bdecc00016bb04f?t=1730454588964&xsec_token=ABzpzlebQmDljSku3JMEon4Jq4Q6ASIwi3h3V_NU5uNEQ%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7（不包含脚本工具）",
            imgUrl: "7.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            price: "316",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661509034965621041&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c472ee07642600010b36ca?t=1730606336233&xsec_token=ABGPrCwWYInDmIcApgAeK447qvMdQ2WGAw1-fJ4H8cdTc%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “萌新四件套” 模板优惠折扣套装",
            imgUrl: "1.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.43.3d9161ebqsyGQ9&id=680865926077",
            price: "69.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.43.3d9161ebqsyGQ9&id=680865926077",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661506331300477333&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c47299729b0e0001fe25b0?t=1730606336233&xsec_token=ABdsk-YP38akFZOxiIrrzn3BgrqSQtbBoancFHqqMDlAQ%3D&xsec_source=app_arkselfshare"
          },
          // {
          //   title: "ViccoVlog 达芬奇原创转场模板合集 V1",
          //   imgUrl: "4.jpg",
          //   buyUrl:
          //     "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.51.3d9161ebqsyGQ9&id=732309176123",
          //   price: "40.90",
          // },
        ]
      } ,
      Variety_Show_Text_Templates: {
        preTitle: "Variety Show Titles",
        title: "综艺花字模板",
        imgUrl: "13.jpg",
        tryUrl: "https://pan.baidu.com/s/1imwG_fCEh0yD8gBbHqb0MQ?pwd=wqfs",
        more: [
          {
            title: "ViccoVlog 达芬奇原创 综艺花字模板 Variety Text Templates",
            imgUrl: "2.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.39.3d9161ebqsyGQ9&id=655564899374",
            price: "28.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.39.3d9161ebqsyGQ9&id=655564899374",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661394857664105816&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c4507e3bdecc00016bb96a?t=1730454815645&xsec_token=ABzpzlebQmDljSku3JMEon4N7Ma00-mzYmDkJ_rfo4SHo%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7（不包含脚本工具）",
            imgUrl: "7.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            price: "316",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661509034965621041&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c472ee07642600010b36ca?t=1730606336233&xsec_token=ABGPrCwWYInDmIcApgAeK447qvMdQ2WGAw1-fJ4H8cdTc%3D&xsec_source=app_arkselfshare"
          },
          // {
          //   title: "ViccoVlog 达芬奇原创标题模板合集 V1",
          //   imgUrl: "5.jpg",
          //   buyUrl:
          //     "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   price: "179",
          //   taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   douYinUrl: "",
          //   xiaoHongShuUrl: ""
          // },
        ]
      },
      VChart_Templates: {
        preTitle: "Vchart Animations",
        title: "图表模板",
        imgUrl: "11.jpg",
        tryUrl: "https://pan.baidu.com/s/1E6QFvGJE-mQvLTnVJ4_UfA?pwd=7nf2",
        more: [
          {
            title: "ViccoVlog 达芬奇原创 VChart Templates 图表套件动态模板",
            imgUrl: "2.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.27.3d9161ebqsyGQ9&id=646325862748",
            price: "35.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.27.3d9161ebqsyGQ9&id=646325862748",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661521971398738578&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c4540bfe1f3d0001d6ce10?t=1730454811835&xsec_token=ABX-gWq_Hnhzdh2I037dKACls9DxyBgm3VzIB5dkXIbS4%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7（不包含脚本工具）",
            imgUrl: "7.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            price: "316",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661509034965621041&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c472ee07642600010b36ca?t=1730606336233&xsec_token=ABGPrCwWYInDmIcApgAeK447qvMdQ2WGAw1-fJ4H8cdTc%3D&xsec_source=app_arkselfshare"
          },
        ]
      },
      Wedding_Titles_Template: {
        preTitle: "Wedding Titles",
        title: "婚礼标题模板",
        imgUrl: "16.jpg",
        tryUrl: "https://pan.baidu.com/s/1Dvnb4C0MvIroUczSPErO2w?pwd=tte4",
        more: [
          {
            title: "ViccoVlog 达芬奇原创 婚礼标题模板 Wedding Titles",
            imgUrl: "2.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.47.3d9161ebqsyGQ9&id=691860858769",
            price: "24.90",
            taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.47.3d9161ebqsyGQ9&id=691860858769",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661400943523685701&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c455beb752d60001c12cda?t=1730454811835&xsec_token=ABg_E264vU-9lQgnNCNNYB29M8el1uewKIptwzhpxVgjg%3D&xsec_source=app_arkselfshare"
          },
          {
            title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7（不包含脚本工具）",
            imgUrl: "7.jpg",
            buyUrl:
              "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            price: "316",
            taoBaoUrl: "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
            douYinUrl: "https://haohuo.jinritemai.com/ecommerce/trade/detail/index.html?id=3661509034965621041&origin_type=604",
            xiaoHongShuUrl: "https://www.xiaohongshu.com/goods-detail/65c472ee07642600010b36ca?t=1730606336233&xsec_token=ABGPrCwWYInDmIcApgAeK447qvMdQ2WGAw1-fJ4H8cdTc%3D&xsec_source=app_arkselfshare"
          },
          // {
          //   title: "ViccoVlog 达芬奇原创标题模板合集 V1",
          //   imgUrl: "5.jpg",
          //   buyUrl:
          //     "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   price: "179",
          //   taoBaoUrl: "https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23500862039.53.3d9161ebqsyGQ9&id=732786015863",
          //   douYinUrl: "",
          //   xiaoHongShuUrl: ""
          // },
        ]
      }
    },
    videoData: {
      Free_Templates: {
        title: "免费合集",
        videoUrl:
          "//player.bilibili.com/player.html?aid=854530447&bvid=BV1y54y1o7YS&cid=735227710&p=1",
        more: [
          {
            title: "[Vicco] 达芬奇自制浏览器窗口覆盖模版 | 国庆免费合集更新 | Safari & Edge 窗口布局",
            videoUrl:
              "https://www.bilibili.com/video/BV1Ajx4eMEs7/",
            imgUrl: "0-22.png",
          },
          {
            title: "[Vicco] 达芬奇自制自定义辅助线工具 | 白嫖时间 | 免费合集更新",
            videoUrl:
              "https://www.bilibili.com/video/BV1yqpRe1Emj/",
            imgUrl: "0-21.png",
          },
          {
            title: "[Vicco] 电影黑边遮幅更好用了 | 免费合集更新",
            videoUrl:
              "https://www.bilibili.com/video/BV1dS411w7pC/",
            imgUrl: "0-20.png",
          },
          {
            title: "[Vicco] 达芬奇自制画线工具 | 2024新年第一弹 | 免费下载",
            videoUrl:
              "https://www.bilibili.com/video/BV1SN4y1B7h2/?spm_id_from=333.337",
            imgUrl: "0-19.png",
          },
          {
            title: "[Vicco] 好用不嫌多的年度免费模板合集 | 达芬奇预设模板分享",
            videoUrl:
              "https://www.bilibili.com/video/BV1Hf4y1E7cT/?spm_id_from=333.999.0.0",
            imgUrl: "0-1.png",
          },
          {
            title: "[Vicco] 达芬奇原创弹幕生成器模板 | 双11白嫖",
            videoUrl:
              "https://www.bilibili.com/video/BV1mc411d71j/?spm_id_from=333.999.0.0",
            imgUrl: "0-2.png",
          },
          {
            title: "[Vicco] 在达芬奇Fusion中快速制作浮雕金字 | 免费模板更新",
            videoUrl:
              "https://www.bilibili.com/video/BV1eW4y1f77G/?spm_id_from=333.999.0.0",
            imgUrl: "0-3.png",
          },
          {
            title: "[Vicco] 一个打十个的超级转场V2来啦 | 四种转场任意组合",
            videoUrl:
              "https://www.bilibili.com/video/BV1ge4y137kw/?spm_id_from=333.999.0.0",
            imgUrl: "0-4.png",
          },
          {
            title: "[Vicco] 达芬奇打字机效果模板 V2 | 新模板 新用法",
            videoUrl:
              "https://www.bilibili.com/video/BV1Xt4y1j7YC/?spm_id_from=333.999.0.0",
            imgUrl: "0-5.png",
          },
          {
            title: "[Vicco] 达芬奇原创自制 新闻滚动字幕模板 | 粉丝福利",
            videoUrl:
              "https://www.bilibili.com/video/BV1EP41157fi/?spm_id_from=333.999.0.0",
            imgUrl: "0-6.png",
          },
          {
            title: "[Vicco] 达芬奇自制照片相框模板 | 照片制作效率小工具",
            videoUrl:
              "https://www.bilibili.com/video/BV19S4y177bD/?spm_id_from=333.999.0.0",
            imgUrl: "0-7.png",
          },
          {
            title: "[Vicco] 达芬奇自制毛玻璃效果模板 | 白嫖时间 | 双倍快乐",
            videoUrl:
              "https://www.bilibili.com/video/BV1c94y197HQ/?spm_id_from=333.999.0.0",
            imgUrl: "0-8.png",
          },
          {
            title: "[Vicco] 好用不嫌多的模板又又更新啦~ | 图像基础&MG动画",
            videoUrl:
              "https://www.bilibili.com/video/BV15V4y1n7DM/?spm_id_from=333.999.0.0",
            imgUrl: "0-9.png",
          },
          {
            title: "[Vicco] 在达芬奇中制作一个有趣的放大镜 | 节点分享",
            videoUrl:
              "https://www.bilibili.com/video/BV1sL411x7V7/?spm_id_from=333.999.0.0",
            imgUrl: "0-10.png",
          },
          {
            title: "[Vicco] 在达芬奇中为文本划出高亮标注效果 | 预设免费下载",
            videoUrl:
              "https://www.bilibili.com/video/BV1pf4y1b7JM/?spm_id_from=333.999.0.0",
            imgUrl: "0-11.png",
          },
          {
            title:
              "[Vicco] 用达芬奇三十秒制作一个计数器 | 预设下载 | 达芬奇三分钟",
            videoUrl:
              "https://www.bilibili.com/video/BV1z64y1m73s/?spm_id_from=333.999.0.0",
            imgUrl: "0-12.png",
          },
          {
            title: "[Vicco] 比转场2.0版本更厉害的超级转场来了，一个打十个！",
            videoUrl:
              "https://www.bilibili.com/video/BV18p4y1H75V/?spm_id_from=333.999.0.0",
            imgUrl: "0-13.png",
          },
          {
            title: "[Vicco] 用达芬奇制作漫画中超酷超动感速度线 | 节点预设分享",
            videoUrl:
              "https://www.bilibili.com/video/BV1zK4y1p72e/?spm_id_from=333.999.0.0",
            imgUrl: "0-14.png",
          },
          {
            title: "[Vicco] 达芬奇自制效果预设 骨架屏一键三连动画 | 白嫖来了",
            videoUrl:
              "https://www.bilibili.com/video/BV1Z54y147i2/?spm_id_from=333.999.0.0",
            imgUrl: "0-15.png",
          },
          {
            title:
              "[Vicco] 在达芬奇中像AE一样一步玩抽帧 | 自制抽帧插件双节白嫖",
            videoUrl:
              "https://www.bilibili.com/video/BV13f4y1Q7ac/?spm_id_from=333.999.0.0",
            imgUrl: "0-16.png",
          },
          {
            title: "[Vicco] 在达芬奇中玩转快照效果 | 达芬奇三分钟 | 预设下载",
            videoUrl:
              "https://www.bilibili.com/video/BV1tp4y1i7B4/?spm_id_from=333.999.0.0",
            imgUrl: "0-17.png",
          },
          {
            title: "[Vicco] 在达芬奇复刻Peter McKinnon标题字幕预设 | 白嫖下载",
            videoUrl:
              "https://www.bilibili.com/video/BV1Lk4y1B7Qu/?spm_id_from=333.999.0.0",
            imgUrl: "0-18.png",
          },
        ],
      },
      Batch_Copy_Tools: {
        title: "ViccoVlog批量复制工具",
        videoUrl:
          "//player.bilibili.com/player.html?isOutside=true&aid=1856345062&bvid=BV1WW42197x6&cid=1630145213&p=1",
        // more: [
        //   {
        //     title: "[大仙] ViccoVlog原创达芬奇字幕转工具",
        //     videoUrl:
        //       "https://www.bilibili.com/video/BV14H4y1G7Ao/?share_source=copy_web&vd_source=b3d7e1a2028c00adb444f2a89fe6cc38",
        //     imgUrl: "9-1.png",
        //   },
        // ]
      },
      Subtitle_Tools: {
        title: "ViccoVlog字幕工具",
        videoUrl:
          "//player.bilibili.com/player.html?isOutside=true&aid=1606208568&bvid=BV1xm421g7DR&cid=1615623935&p=1",
        more: [
          {
            title: "[大仙] ViccoVlog原创达芬奇字幕转工具",
            videoUrl:
              "https://www.bilibili.com/video/BV14H4y1G7Ao/?share_source=copy_web&vd_source=b3d7e1a2028c00adb444f2a89fe6cc38",
            imgUrl: "9-1.png",
          },
        ]
      },
      Slide_Motions_Templates: {
        title: "Slide Motion 滑屏动效模板",
        videoUrl:
          "//player.bilibili.com/player.html?aid=1253150022&bvid=BV1qJ4m1p7oo&cid=1508340257&p=1",
      },
      AERO_Glass_Transitions: {
        title: "AERO Glass 玻璃转场模板",
        videoUrl:
          "//player.bilibili.com/player.html?aid=543907305&bvid=BV1Sv4y1f7ib&cid=289438162&p=1",
      },
      Brush_Paint_Titles_Template: {
        title: "Brush Paint Titles 油彩笔刷标题模板",
        videoUrl:
          "//player.bilibili.com/player.html?aid=814454981&bvid=BV1hG4y1e7mQ&cid=803057158&p=1",
      },
      CallOut_Templates: {
        title: "Call Out 呼出框模板 V2",
        videoUrl:
          "//player.bilibili.com/player.html?isOutside=true&aid=1454724404&bvid=BV1si421D7pt&cid=1544442904&p=1",
        more: [
          {
            title: "[ViccoVlog] 达芬奇Call-out呼出框自制预设包 V1",
            videoUrl:
              "https://www.bilibili.com/video/BV1T5411e7oN/?spm_id_from=333.337.search-card.all.click&vd_source=b026585dec7c9619ae43e1f12a197818",
            imgUrl: "8-1.png",
          },
        ]
      },
      Cinematic_Titles_Template: {
        title: "Cinematic Titles 电影标题模板",
        videoUrl:
          "//player.bilibili.com/player.html?aid=272616225&bvid=BV1Ec411u71w&cid=1171875843&p=1",
        more: [
          {
            title: "[模板更新] 新制作的老电视覆盖特效模板",
            videoUrl:
              "https://www.bilibili.com/video/BV1Y54FemE9N/",
            imgUrl: "7-0.png",
          },
          {
            title: "[Vicco] 达芬奇电影标题竖排文字更新 | 19个模版",
            videoUrl:
              "https://www.bilibili.com/video/BV1rS411w7Ah/",
            imgUrl: "7-3.png",
          },
          {
            title: "[ViccoVlog] 一秒制作图像遮罩标题动画",
            videoUrl:
              "https://www.bilibili.com/video/BV18x4y1k7cK/",
            imgUrl: "7-2.png",
          },
          {
            title: "[Vicco]在Fusion中制作噪波发光遮罩文字 | 模板更新",
            videoUrl:
              "https://www.bilibili.com/video/BV14W421N7P6/?spm_id_from=333.999.0.0",
            imgUrl: "7-1.png",
          },
        ]
      },
      Creative_Titles_Templates: {
        title: "Creative Titles 创意标题模板",
        videoUrl:
          "//player.bilibili.com/player.html?aid=296329603&bvid=BV11F411J7N5&cid=503851540&p=1",
      },
      Glitch_Lower_Thirds_30FPS: {
        title: "Glitch Titles 故障标题模板 30帧",
        videoUrl:
          "//player.bilibili.com/player.html?aid=843035488&bvid=BV1D54y167sL&cid=264329627&p=1",
      },
      Glitch_Lower_Thirds_60FPS: {
        title: "Glitch Titles 故障标题模板 60帧",
        videoUrl:
          "//player.bilibili.com/player.html?aid=843035488&bvid=BV1D54y167sL&cid=264329627&p=1",
      },
      Image_Effect_Templates: {
        title: "Image Effects 图像基础动画模板 V2",
        videoUrl:
          "//player.bilibili.com/player.html?aid=757114724&bvid=BV1L64y1D7EW&cid=308801461&p=1",
        more: [
          {
            title: "[Vicco] 达芬奇原创自制图像对比模版 | 两种模式 | 左右对比 | 同步操作 | 图像基础动画更新",
            videoUrl: "https://www.bilibili.com/video/BV1GdmEYNEb2/",
            imgUrl: "1-7.png",
          },
          {
            title: "[ViccoVlog] 让你的Vlog活泼起来 | 达芬奇原创剪贴画模板",
            videoUrl: " https://www.bilibili.com/video/BV1DZ421h7Wm",
            imgUrl: "1-6.png",
          },
          {
            title: "达芬奇图像基础动画模板 V2 大更新,快速实现卡点动效",
            videoUrl: "https://www.bilibili.com/video/BV1eF411W7fw/",
            imgUrl: "1-1.png",
          },
          {
            title: "在达芬奇里十秒制作3D运镜效果,图像基础动画模板更新",
            videoUrl: "https://www.bilibili.com/video/BV1P44y1D7MK/",
            imgUrl: "1-2.png",
          },
          {
            title: "在达芬奇剪辑页面一键创建自定义遮罩,图像基础动画模板更新",
            videoUrl: "https://www.bilibili.com/video/BV1rD4y1H7Bz/",
            imgUrl: "1-3.png",
          },
          {
            title: "全新的动画交互方式,图像基础动画V2.1更新",
            videoUrl: "https://www.bilibili.com/video/BV16M4y127gA/",
            imgUrl: "1-4.png",
          },
          {
            title: "达芬奇自制滑动路径模板,再也不用去Fusion里画路径了",
            videoUrl: "https://www.bilibili.com/video/BV1BP411t7MH/",
            imgUrl: "1-5.png",
          },
        ],
      },
      Innovation_Titles_Templates: {
        title: "INNOVATION TITLES 创新标题",
        videoUrl:
          "//player.bilibili.com/player.html?aid=795268305&bvid=BV1oC4y1s7Cg&cid=179307803&p=1",
      },
      MG_Animation_Templates: {
        title: "MG Animations 标题模板",
        videoUrl:
          "//player.bilibili.com/player.html?aid=457901248&bvid=BV1n5411575L&cid=259006113&p=1",
        more: [
          {
            title: "被强烈要求更新的两个MG动画 | 区域选区 | 圆角线框选区模版",
            videoUrl: "https://www.bilibili.com/video/BV1CUtWevEKX/",
            imgUrl: "5-7.png",
          },
          {
            title: "达芬奇MG动画模板更新 | 背景循环动画 | 鼠标 | 箭头滑动",
            videoUrl: "https://www.bilibili.com/video/BV1dFHKeXEu1/",
            imgUrl: "5-6.png",
          },
          {
            title: "达芬奇MG动画模板更新 | 角度与背景循环动画",
            videoUrl: "https://www.bilibili.com/video/BV11s421T7Q4/",
            imgUrl: "5-5.png",
          },
          {
            title: "达芬奇自制MG动画套件模板大更新",
            videoUrl: "https://www.bilibili.com/video/BV1Pv411W7mk/",
            imgUrl: "5-1.png",
          },
          {
            title: "在达芬奇中使用跟踪节点制作标尺动画",
            videoUrl: "https://www.bilibili.com/video/BV1Bo4y1k7id/",
            imgUrl: "5-2.png",
          },
          {
            title: "好用不嫌多的模板又又更新啦~,图像基础&MG动画",
            videoUrl: "https://www.bilibili.com/video/BV15V4y1n7DM/",
            imgUrl: "5-4.png",
          },
        ],
      },
      Rainbow_Transition: {
        title: "Rainbow Transitions 彩虹转场模板",
        videoUrl:
          "//player.bilibili.com/player.html?aid=544804848&bvid=BV1ii4y1P7uN&cid=315713551&p=1",
      },
      SocialMedia_Animation_Templates: {
        title: "Social Media Animations 社交媒体模板",
        videoUrl:
          "//player.bilibili.com/player.html?aid=631641206&bvid=BV1bb4y1k7aU&cid=369657613&p=1",
      },
      Split_Screen_Templates: {
        title: "Split Screen 分屏模板 V2",
        videoUrl:
          "//player.bilibili.com/player.html?aid=630117356&bvid=BV1Bb4y1D7t8&cid=324377867&p=1",
        more: [
          {
            title: "[Vicco] 达芬奇原创动态分屏模板 | 分屏模板更新",
            videoUrl: "https://www.bilibili.com/video/BV1j94y1G7d4/?spm_id_from=333.999.0.0/",
            imgUrl: "3-5.png",
          },
          {
            title: "达芬奇原创分屏模板 V2,自适应关键帧",
            videoUrl: "https://www.bilibili.com/video/BV1Mi4y1D7Q3/",
            imgUrl: "3-1.png",
          },
          {
            title: "达芬奇原创分屏模板V2更新,自由调整&动画更新",
            videoUrl: "https://www.bilibili.com/video/BV1mY4y1j7Mx/",
            imgUrl: "3-2.png",
          },
          {
            title: "达芬奇自制分屏模板又又更新啦,有一丢丢不一样",
            videoUrl: "https://www.bilibili.com/video/BV1ug411Q7rt/",
            imgUrl: "3-3.png",
          },
          {
            title: "好用不嫌多的模板又更新啦,分屏 & 裁切工具 & 划像工具",
            videoUrl: "https://www.bilibili.com/video/BV1vG4y1f7Q2/",
            imgUrl: "3-4.png",
          },
        ],
      },
      Standard_Transitions_V2: {
        title: "Standard Transitions V2 标准转场 V2",
        videoUrl:
          "//player.bilibili.com/player.html?aid=209119805&bvid=BV1Zh411t77n&cid=437978381&p=1",
        more: [
          {
            title: "达芬奇DaVinci自制免费转场预设包2.0,官方转场一样的使用体验",
            videoUrl: "https://www.bilibili.com/video/BV1nE411w7RR/",
            imgUrl: "2-1.png",
          },
          {
            title: "达芬奇标准转场V2更新进行时,全新2个类型,新增11套模板",
            videoUrl: "https://www.bilibili.com/video/BV1E5411X7v7/",
            imgUrl: "2-2.png",
          },
          {
            title: "达芬奇自制光斑转场模板,标准转场更新",
            videoUrl: "https://www.bilibili.com/video/BV1n44y1Z7vh/",
            imgUrl: "2-3.png",
          },
          {
            title: "在达芬奇中如何制作翻页转场效果,标准转场更新",
            videoUrl: "https://www.bilibili.com/video/BV1JP4y1y74b/",
            imgUrl: "2-4.png",
          },
          {
            title: "达芬奇像素网格转场 & 焦点转场更新,10组全新模板",
            videoUrl: "https://www.bilibili.com/video/BV1VY411D7cJ/",
            imgUrl: "2-5.png",
          },
        ],
      },
      Variety_Show_Text_Templates: {
        title: "Variety Show Titles 综艺花字模板",
        videoUrl:
          "//player.bilibili.com/player.html?aid=250275473&bvid=BV1Vv411P7TY&cid=404167538&p=1",
        more: [
          {
            title: "达芬奇原创综艺花字模板大更新,52套模板合集",
            videoUrl: "https://www.bilibili.com/video/BV1mL4y1z72z/",
            imgUrl: "4-1.png",
          },
          {
            title: "十月模板又更新了,花字模板 & 免费合集更新",
            videoUrl: "https://www.bilibili.com/video/BV1gN4y1A7SV/",
            imgUrl: "4-2.png",
          },
        ],
      },
      VChart_Templates: {
        title: "Vchart Animations 图表模板",
        videoUrl:
          "//player.bilibili.com/player.html?aid=760940646&bvid=BV1f64y1k7bk&cid=347267022&p=1",
        more: [
          {
            title: "达芬奇自制小数位金融分隔符计数器 | 图表模板更新",
            videoUrl: "https://www.bilibili.com/video/BV1gzsreREbg/",
            imgUrl: "6-5.png",
          },
          {
            title: "[Vicco] 图表套件更新 | 滚动数字 | 计时器 | 更新11个模板",
            videoUrl: "https://www.bilibili.com/video/BV1vY411m7GA/",
            imgUrl: "6-1.png",
          },
          {
            title: "[Vicco] 图表模板常规更新说明 | 五个模板",
            videoUrl: "https://www.bilibili.com/video/BV1th41177bN/",
            imgUrl: "6-2.png",
          },
          {
            title:
              "[Vicco] 七月模板常规更新 | 分屏 | 图表 | 媒体动画 | 竖排文字",
            videoUrl: "https://www.bilibili.com/video/BV1bm4y1Y7rz/",
            imgUrl: "6-3.png",
          },
          {
            title: "[Vicco] 达芬奇自制翻转数字模板 | 图表模板免费更新",
            videoUrl: "https://www.bilibili.com/video/BV1jF411r7Bq/",
            imgUrl: "6-4.png",
          },
        ],
      },
      Wedding_Titles_Template: {
        title: "Wedding Titles 婚礼标题模板",
        videoUrl:
          "//player.bilibili.com/player.html?aid=562287253&bvid=BV1ov4y1D72Z&cid=882072747&p=1",
      },
    },

    effectData: {
      Free_Templates: [
        {
          name: "safari_窗口",
          videoName: "特效/V3-0006_.00000000.mp4",
          classify: "特效",
          newTemplate: true
        },
        {
          name: "edge_窗口",
          videoName: "特效/V3-0005_.00000000.mp4",
          classify: "特效",
          newTemplate: true
        },
        {
          name: "相机_记录_覆盖_横屏",
          videoName: "生成器/V3-0003_.00000000.mp4",
          classify: "生成器",
          newTemplate: true
        },
        {
          name: "相机_记录_覆盖_竖屏",
          videoName: "生成器/V3-0004_.00000000.mp4",
          classify: "生成器",
          newTemplate: true
        },
        {
          name: "辅助线_自定义",
          videoName: "生成器/V3-0002_.00000000.mp4",
          classify: "生成器",
          newTemplate: true
        },
        {
          name: "纯色",
          videoName: "生成器/V3-0001_.00000000.mp4",
          classify: "生成器",
          newTemplate: true
        },
        {
          name: "弹幕",
          videoName: "生成器/V1-0021_21.mp4",
          classify: "生成器"
        },
        {
          name: "画线",
          videoName: "生成器/V1-0022_22.mp4",
          classify: "生成器"
        },
        {
          name: "Ez_Glitch",
          videoName: "特效/V1-0001_1.mp4",
          classify: "特效",
        },
        {
          name: "形状_毛玻璃",
          videoName: "特效/V1-0002_2.mp4",
          classify: "特效",
        },
        {
          name: "形状_矩形",
          videoName: "特效/V1-0003_3.mp4",
          classify: "特效",
        },
        {
          name: "抽帧工具",
          videoName: "特效/V1-0004_4.mp4",
          classify: "特效",
        },
        {
          name: "放大镜_圆形",
          videoName: "特效/V1-0005_5.mp4",
          classify: "特效",
        },
        {
          name: "放大镜_矩形",
          videoName: "特效/V1-0006_6.mp4",
          classify: "特效",
        },
        {
          name: "相框",
          videoName: "特效/V1-0007_7.mp4",
          classify: "特效",
        },
        {
          name: "磨砂玻璃_文字",
          videoName: "特效/V1-0008_8.mp4",
          classify: "特效",
        },
        {
          name: "金字_贴图",
          videoName: "特效/V1-0009_9.mp4",
          classify: "特效",
        },
        {
          name: "骨架屏_Bilibili",
          videoName: "特效/V1-0010_10.mp4",
          classify: "特效",
        },
        {
          name: "骨架屏_Youtube",
          videoName: "特效/V1-0011_11.mp4",
          classify: "特效",
        },
        {
          name: "摄影_iPhone_横屏_01",
          videoName: "生成器/V1-0001_1.mp4",
          classify: "生成器",
        },
        {
          name: "摄影_iPhone_横屏_02",
          videoName: "生成器/V1-0002_2.mp4",
          classify: "生成器",
        },
        {
          name: "摄影_iPhone_竖屏_01",
          videoName: "生成器/V1-0003_3.mp4",
          classify: "生成器",
        },
        {
          name: "摄影_iPhone_竖屏_02",
          videoName: "生成器/V1-0004_4.mp4",
          classify: "生成器",
        },
        {
          name: "摄影_单反",
          videoName: "生成器/V1-0005_5.mp4",
          classify: "生成器",
        },
        {
          name: "星耀",
          videoName: "生成器/V1-0006_6.mp4",
          classify: "生成器",
        },
        {
          name: "渐变",
          videoName: "生成器/V1-0007_7.mp4",
          classify: "生成器",
        },
        {
          name: "照片相框",
          videoName: "生成器/V1-0008_8.mp4",
          classify: "生成器",
        },
        {
          name: "照片相框_文字",
          videoName: "生成器/V1-0009_9.mp4",
          classify: "生成器",
        },
        {
          name: "电影黑边工具",
          videoName: "生成器/V1-0010_10.mp4",
          classify: "生成器",
        },
        {
          name: "辅助_对称位置",
          videoName: "生成器/V1-0011_11.mp4",
          classify: "生成器",
        },
        {
          name: "辅助_行_列",
          videoName: "生成器/V1-0012_12.mp4",
          classify: "生成器",
        },
        {
          name: "辅助_角度",
          videoName: "生成器/V1-0013_13.mp4",
          classify: "生成器",
        },
        {
          name: "辅助_距离",
          videoName: "生成器/V1-0014_14.mp4",
          classify: "生成器",
        },
        {
          name: "辅助_黄金比例",
          videoName: "生成器/V1-0015_15.mp4",
          classify: "生成器",
        },
        {
          name: "速度线_01",
          videoName: "生成器/V1-0016_16.mp4",
          classify: "生成器",
        },
        {
          name: "速度线_02",
          videoName: "生成器/V1-0017_17.mp4",
          classify: "生成器",
        },
        {
          name: "速度线_瀑布",
          videoName: "生成器/V1-0018_18.mp4",
          classify: "生成器",
        },
        {
          name: "速度线_穿越",
          videoName: "生成器/V1-0019_19.mp4",
          classify: "生成器",
        },
        {
          name: "高亮文本",
          videoName: "生成器/V1-0020_20.mp4",
          classify: "生成器",
        },
        {
          name: "打字机_V2",
          videoName: "标题/V1-0001_.00000000.mp4",
          classify: "标题",
        },
        {
          name: "KTV_标题_单行",
          videoName: "标题/V1-0002_.00000000.mp4",
          classify: "标题",
        },
        {
          name: "KTV_标题_双行",
          videoName: "标题/V1-0003_.00000000.mp4",
          classify: "标题",
        },
        {
          name: "PTMC_标题",
          videoName: "标题/V1-0004_.00000000.mp4",
          classify: "标题",
        },
        {
          name: "圆角_文本",
          videoName: "标题/V1-0005_.00000000.mp4",
          classify: "标题",
        },
        {
          name: "竖排文字_V2",
          videoName: "标题/V1-0006_.00000000.mp4",
          classify: "标题",
        },
        {
          name: "计数器",
          videoName: "标题/V1-0007_.00000000.mp4",
          classify: "标题",
        },
        {
          name: "人名条01",
          videoName: "标题/V2-0001_.00000000.mp4",
          classify: "标题-新闻",
        },
        {
          name: "人名条02",
          videoName: "标题/V2-0002_.00000000.mp4",
          classify: "标题-新闻",
        },
        {
          name: "人名条03",
          videoName: "标题/V2-0003_.00000000.mp4",
          classify: "标题-新闻",
        },
        {
          name: "标题 滚动文本 01",
          videoName: "标题/V2-0004_.00000000.mp4",
          classify: "标题-新闻",
        },
        {
          name: "标题 滚动文本 02",
          videoName: "标题/V2-0005_.00000000.mp4",
          classify: "标题-新闻",
        },
        {
          name: "滚动文本 01",
          videoName: "标题/V2-0006_.00000000.mp4",
          classify: "标题-新闻",
        },
        {
          name: "滚动文本 02",
          videoName: "标题/V2-0007_.00000000.mp4",
          classify: "标题-新闻",
        },
        {
          name: "滚动文本 03",
          videoName: "标题/V2-0008_.00000000.mp4",
          classify: "标题-新闻",
        },
        {
          name: "角标 01",
          videoName: "标题/V2-0009_.00000000.mp4",
          classify: "标题-新闻",
        },
        {
          name: "角标 0",
          videoName: "标题/V2-0010_.00000000.mp4",
          classify: "标题-新闻",
        },

        {
          name: "超级转场_变焦_入&入",
          videoName: "转场/V1-0001_1.mp4",
          classify: "转场",
        },
        {
          name: "超级转场_变焦_入&出",
          videoName: "转场/V1-0002_2.mp4",
          classify: "转场",
        },
        {
          name: "超级转场_变焦_出&入",
          videoName: "转场/V1-0003_3.mp4",
          classify: "转场",
        },
        {
          name: "超级转场_变焦_出&出",
          videoName: "转场/V1-0004_4.mp4",
          classify: "转场",
        },
        {
          name: "超级转场_自旋",
          videoName: "转场/V1-0005_5.mp4",
          classify: "转场",
        },
        {
          name: "超级转场_滑动",
          videoName: "转场/V1-0006_6.mp4",
          classify: "转场",
        },
        {
          name: "超级转场_叠化_叠化",
          videoName: "转场/V1-0007_7.mp4",
          classify: "转场",
        },
        {
          name: "超级转场_叠化_侵蚀",
          videoName: "转场/V1-0008_8.mp4",
          classify: "转场",
        },
        {
          name: "超级转场_叠化_噪波",
          videoName: "转场/V1-0009_9.mp4",
          classify: "转场",
        },
        {
          name: "超级转场_变焦_自旋",
          videoName: "转场/V1-0010_10.mp4",
          classify: "转场",
        },
        {
          name: "超级转场_变焦_滑动",
          videoName: "转场/V1-0011_11.mp4",
          classify: "转场",
        },
        {
          name: "故障_01",
          videoName: "转场/V1-0012_12.mp4",
          classify: "转场",
        },
        {
          name: "故障_02",
          videoName: "转场/V1-0013_13.mp4",
          classify: "转场",
        },
        {
          name: "故障_03",
          videoName: "转场/V1-0014_14.mp4",
          classify: "转场",
        },
        {
          name: "故障_04",
          videoName: "转场/V1-0015_15.mp4",
          classify: "转场",
        },
        {
          name: "故障_05",
          videoName: "转场/V1-0016_16.mp4",
          classify: "转场",
        },
      ],
      Batch_Copy_Tools: [],
      Subtitle_Tools: [
        {
          "name": "字幕 仪表板",
          "videoName": "V1-0001_.00000000.mp4",
          newTemplate: true
        },
        {
          "name": "字幕 基础",
          "videoName": "V1-0002_.00000000.mp4",
          newTemplate: true
        },
        {
          "name": "字幕 实线",
          "videoName": "V1-0003_.00000000.mp4",
          newTemplate: true
        },
        {
          "name": "字幕 手绘",
          "videoName": "V1-0004_.00000000.mp4",
          newTemplate: true
        },
        {
          "name": "字幕 手绘 边框",
          "videoName": "V1-0005_.00000000.mp4",
          newTemplate: true
        },
        {
          "name": "字幕 撕纸",
          "videoName": "V1-0006_.00000000.mp4",
          newTemplate: true
        },
        {
          "name": "字幕 故障",
          "videoName": "V1-0007_.00000000.mp4",
          newTemplate: true
        },
        {
          "name": "字幕 波浪线",
          "videoName": "V1-0008_.00000000.mp4",
          newTemplate: true
        }
      ],
      Slide_Motions_Templates: [
        {
          "name": "垂直 1x2 01",
          "videoName": "特效/多屏滑动/V1-0001_C1.mp4",
          "classify": "特效-多屏滑动"
        },
        {
          "name": "垂直 1x2 02",
          "videoName": "特效/多屏滑动/V1-0002_C2.mp4",
          "classify": "特效-多屏滑动"
        },
        {
          "name": "垂直 1x2 03",
          "videoName": "特效/多屏滑动/V1-0003_C3.mp4",
          "classify": "特效-多屏滑动"
        },
        {
          "name": "垂直 1x3 01",
          "videoName": "特效/多屏滑动/V1-0004_C4.mp4",
          "classify": "特效-多屏滑动"
        },
        {
          "name": "垂直 1x3 02",
          "videoName": "特效/多屏滑动/V1-0005_C5.mp4",
          "classify": "特效-多屏滑动"
        },
        {
          "name": "垂直 1x3 03",
          "videoName": "特效/多屏滑动/V1-0006_C6.mp4",
          "classify": "特效-多屏滑动"
        },
        {
          "name": "垂直 1xT",
          "videoName": "特效/多屏滑动/V1-0007_C7.mp4",
          "classify": "特效-多屏滑动"
        },
        {
          "name": "垂直 Tx1",
          "videoName": "特效/多屏滑动/V1-0008_C8.mp4",
          "classify": "特效-多屏滑动"
        },
        {
          "name": "水平 1x2 01",
          "videoName": "特效/多屏滑动/V1-0009_C9.mp4",
          "classify": "特效-多屏滑动"
        },
        {
          "name": "水平 1x2 02",
          "videoName": "特效/多屏滑动/V1-0010_C10.mp4",
          "classify": "特效-多屏滑动"
        },
        {
          "name": "水平 1x2 03",
          "videoName": "特效/多屏滑动/V1-0011_C11.mp4",
          "classify": "特效-多屏滑动"
        },
        {
          "name": "水平 1x3 01",
          "videoName": "特效/多屏滑动/V1-0012_C12.mp4",
          "classify": "特效-多屏滑动"
        },
        {
          "name": "水平 1xT 01",
          "videoName": "特效/多屏滑动/V1-0013_C13.mp4",
          "classify": "特效-多屏滑动"
        },
        {
          "name": "水平 1xT 02",
          "videoName": "特效/多屏滑动/V1-0014_C14.mp4",
          "classify": "特效-多屏滑动"
        },
        {
          "name": "水平 1xT 03",
          "videoName": "特效/多屏滑动/V1-0015_C15.mp4",
          "classify": "特效-多屏滑动"
        },
        {
          "name": "水平 Tx1 01",
          "videoName": "特效/多屏滑动/V1-0016_C16.mp4",
          "classify": "特效-多屏滑动"
        },
        {
          "name": "水平 Tx1 02",
          "videoName": "特效/多屏滑动/V1-0017_C17.mp4",
          "classify": "特效-多屏滑动"
        },
        {
          "name": "水平 Tx1 03",
          "videoName": "特效/多屏滑动/V1-0018_C18.mp4",
          "classify": "特效-多屏滑动"
        },
        {
          "name": "多屏 文字 横屏 01",
          "videoName": "特效/多屏文字横屏/V1-0001_C1.mp4",
          "classify": "特效-多屏文字-横屏"
        },
        {
          "name": "多屏 文字 横屏 02",
          "videoName": "特效/多屏文字横屏/V1-0002_C2.mp4",
          "classify": "特效-多屏文字-横屏"
        },
        {
          "name": "多屏 文字 横屏 03",
          "videoName": "特效/多屏文字横屏/V1-0003_C3.mp4",
          "classify": "特效-多屏文字-横屏"
        },
        {
          "name": "多屏 文字 横屏 04",
          "videoName": "特效/多屏文字横屏/V1-0004_C4.mp4",
          "classify": "特效-多屏文字-横屏"
        },
        {
          "name": "多屏 文字 横屏 05",
          "videoName": "特效/多屏文字横屏/V1-0005_C5.mp4",
          "classify": "特效-多屏文字-横屏"
        },
        {
          "name": "多屏 文字 横屏 06",
          "videoName": "特效/多屏文字横屏/V1-0006_C6.mp4",
          "classify": "特效-多屏文字-横屏"
        },
        {
          "name": "多屏 文字 横屏 07",
          "videoName": "特效/多屏文字横屏/V1-0007_C7.mp4",
          "classify": "特效-多屏文字-横屏"
        },
        {
          "name": "多屏 文字 横屏 08",
          "videoName": "特效/多屏文字横屏/V1-0008_C8.mp4",
          "classify": "特效-多屏文字-横屏"
        },
        {
          "name": "多屏 文字 横屏 09",
          "videoName": "特效/多屏文字横屏/V1-0009_C9.mp4",
          "classify": "特效-多屏文字-横屏"
        },
        {
          "name": "多屏 文字 横屏 10",
          "videoName": "特效/多屏文字横屏/V1-0010_C10.mp4",
          "classify": "特效-多屏文字-横屏"
        },
        {
          "name": "多屏 文字 横屏 11",
          "videoName": "特效/多屏文字横屏/V1-0011_C11.mp4",
          "classify": "特效-多屏文字-横屏"
        },
        {
          "name": "多屏 文字 横屏 12",
          "videoName": "特效/多屏文字横屏/V1-0012_C12.mp4",
          "classify": "特效-多屏文字-横屏"
        },
        {
          "name": "多屏 文字 横屏 13",
          "videoName": "特效/多屏文字横屏/V1-0013_C13.mp4",
          "classify": "特效-多屏文字-横屏"
        },
        {
          "name": "多屏 文字 竖屏 01",
          "videoName": "特效/多屏文字竖屏/V1-0001_V1-0001_C1.mp4",
          "classify": "特效-多屏文字-竖屏"
        },
        {
          "name": "多屏 文字 竖屏 02",
          "videoName": "特效/多屏文字竖屏/V1-0002_V1-0002_C2.mp4",
          "classify": "特效-多屏文字-竖屏"
        },
        {
          "name": "多屏 文字 竖屏 03",
          "videoName": "特效/多屏文字竖屏/V1-0003_V1-0003_C3.mp4",
          "classify": "特效-多屏文字-竖屏"
        },
        {
          "name": "多屏 文字 竖屏 04",
          "videoName": "特效/多屏文字竖屏/V1-0004_V1-0004_C4.mp4",
          "classify": "特效-多屏文字-竖屏"
        },
        {
          "name": "多屏 文字 竖屏 05",
          "videoName": "特效/多屏文字竖屏/V1-0005_V1-0005_C5.mp4",
          "classify": "特效-多屏文字-竖屏"
        },
        {
          "name": "多屏 文字 竖屏 06",
          "videoName": "特效/多屏文字竖屏/V1-0006_V1-0006_C6.mp4",
          "classify": "特效-多屏文字-竖屏"
        },
        {
          "name": "多屏 文字 竖屏 07",
          "videoName": "特效/多屏文字竖屏/V1-0007_V1-0007_C7.mp4",
          "classify": "特效-多屏文字-竖屏"
        },
        {
          "name": "多屏 文字 竖屏 08",
          "videoName": "特效/多屏文字竖屏/V1-0008_V1-0008_C8.mp4",
          "classify": "特效-多屏文字-竖屏"
        },
        {
          "name": "滑动 转场 横屏 01",
          "videoName": "转场/滑动-横屏/V1-0001_C1.mp4",
          "classify": "转场-横屏"
        },
        {
          "name": "滑动 转场 横屏 02",
          "videoName": "转场/滑动-横屏/V1-0002_C2.mp4",
          "classify": "转场-横屏"
        },
        {
          "name": "滑动 转场 横屏 03",
          "videoName": "转场/滑动-横屏/V1-0003_C3.mp4",
          "classify": "转场-横屏"
        },
        {
          "name": "滑动 转场 横屏 04",
          "videoName": "转场/滑动-横屏/V1-0004_C4.mp4",
          "classify": "转场-横屏"
        },
        {
          "name": "滑动 转场 横屏 05",
          "videoName": "转场/滑动-横屏/V1-0005_C5.mp4",
          "classify": "转场-横屏"
        },
        {
          "name": "滑动 转场 横屏 06",
          "videoName": "转场/滑动-横屏/V1-0006_C6.mp4",
          "classify": "转场-横屏"
        },
        {
          "name": "滑动 转场 横屏 07",
          "videoName": "转场/滑动-横屏/V1-0007_C7.mp4",
          "classify": "转场-横屏"
        },
        {
          "name": "滑动 转场 横屏 08",
          "videoName": "转场/滑动-横屏/V1-0008_C8.mp4",
          "classify": "转场-横屏"
        },
        {
          "name": "滑动 转场 竖屏 01",
          "videoName": "转场/滑动-竖屏/V1-0001_V1-0001_C1.mp4",
          "classify": "转场-竖屏"
        },
        {
          "name": "滑动 转场 竖屏 02",
          "videoName": "转场/滑动-竖屏/V1-0002_V1-0002_C2.mp4",
          "classify": "转场-竖屏"
        },
        {
          "name": "滑动 转场 竖屏 03",
          "videoName": "转场/滑动-竖屏/V1-0003_V1-0003_C3.mp4",
          "classify": "转场-竖屏"
        },
        {
          "name": "滑动 转场 竖屏 04",
          "videoName": "转场/滑动-竖屏/V1-0004_V1-0004_C4.mp4",
          "classify": "转场-竖屏"
        },
        {
          "name": "滑动 转场 竖屏 05",
          "videoName": "转场/滑动-竖屏/V1-0005_V1-0005_C5.mp4",
          "classify": "转场-竖屏"
        },
        {
          "name": "滑动 转场 竖屏 06",
          "videoName": "转场/滑动-竖屏/V1-0006_V1-0006_C6.mp4",
          "classify": "转场-竖屏"
        }
      ],
      CallOut_Templates: [
        {
          name: "呼出框 01",
          videoName: "V2/V1-0001_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "呼出框 02",
          videoName: "V2/V1-0002_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "呼出框 03",
          videoName: "V2/V1-0003_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "呼出框 04",
          videoName: "V2/V1-0004_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "呼出框 05",
          videoName: "V2/V1-0005_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "呼出框 06",
          videoName: "V2/V1-0006_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "呼出框 07",
          videoName: "V2/V1-0007_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "呼出框 08",
          videoName: "V2/V1-0008_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "呼出框 09",
          videoName: "V2/V1-0009_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "呼出框 10",
          videoName: "V2/V1-0010_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "呼出框 11",
          videoName: "V2/V1-0011_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "呼出框 12",
          videoName: "V2/V1-0012_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },

        {
          name: "呼出框_01(V1)",
          videoName: "V4-0001_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_02(V1)",
          videoName: "V4-0002_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_03(V1)",
          videoName: "V4-0003_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_04(V1)",
          videoName: "V4-0004_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_03_右(V1)",
          videoName: "V4-0005_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_03_左(V1)",
          videoName: "V4-0006_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_04_右(V1)",
          videoName: "V4-0007_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_04_左(V1)",
          videoName: "V4-0008_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_05_右(V1)",
          videoName: "V4-0009_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_05_左(V1)",
          videoName: "V4-0010_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_06_右(V1)",
          videoName: "V4-0011_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_06_左(V1)",
          videoName: "V4-0012_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_07_右(V1)",
          videoName: "V4-0013_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_07_左(V1)",
          videoName: "V4-0014_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_08_右(V1)",
          videoName: "V4-0015_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_08_左(V1)",
          videoName: "V4-0016_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_09_右(V1)",
          videoName: "V4-0017_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_09_左(V1)",
          videoName: "V4-0018_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_10_右(V1)",
          videoName: "V4-0019_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_10_左(V1)",
          videoName: "V4-0020_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_11_上(V1)",
          videoName: "V4-0021_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_11_下(V1)",
          videoName: "V4-0022_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_12_上(V1)",
          videoName: "V4-0023_.00000000.mp4",
          classify: "V1 旧版",
        },
        {
          name: "呼出框_12_下(V1)",
          videoName: "V4-0024_.00000000.mp4",
          classify: "V1 旧版",
        },
      ],
      SocialMedia_Animation_Templates: [
        {
          name: "京东_圆形",
          videoName: "V1-0056_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "京东_扁平",
          videoName: "V1-0057_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "京东_方形",
          videoName: "V1-0058_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "天猫_圆形",
          videoName: "V1-0059_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "天猫_扁平",
          videoName: "V1-0060_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "天猫_方形",
          videoName: "V1-0061_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "小红书_界面_覆盖",
          videoName: "V1-0062_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "Facebook_圆形",
          videoName: "V1-0001_.00000000.mp4",
        },
        {
          name: "Facebook_扁平",
          videoName: "V1-0002_.00000000.mp4",
        },
        {
          name: "Facebook_方形",
          videoName: "V1-0003_.00000000.mp4",
        },
        {
          name: "Google_Plus_方形",
          videoName: "V1-0004_.00000000.mp4",
        },
        {
          name: "Google_plus_圆形",
          videoName: "V1-0005_.00000000.mp4",
        },
        {
          name: "Google_plus_扁平",
          videoName: "V1-0006_.00000000.mp4",
        },
        {
          name: "Instagram_圆形",
          videoName: "V1-0007_.00000000.mp4",
        },
        {
          name: "Instagram_扁平",
          videoName: "V1-0008_.00000000.mp4",
        },
        {
          name: "Instagram_方形",
          videoName: "V1-0009_.00000000.mp4",
        },
        {
          name: "QQ_圆形",
          videoName: "V1-0010_.00000000.mp4",
        },
        {
          name: "QQ_扁平",
          videoName: "V1-0011_.00000000.mp4",
        },
        {
          name: "QQ_方形",
          videoName: "V1-0012_.00000000.mp4",
        },
        {
          name: "Twitter_圆形",
          videoName: "V1-0013_.00000000.mp4",
        },
        {
          name: "Twitter_扁平",
          videoName: "V1-0014_.00000000.mp4",
        },
        {
          name: "Twitter_方形",
          videoName: "V1-0015_.00000000.mp4",
        },
        {
          name: "Youtube_圆形",
          videoName: "V1-0016_.00000000.mp4",
        },
        {
          name: "Youtube_扁平",
          videoName: "V1-0017_.00000000.mp4",
        },
        {
          name: "Youtube_方形",
          videoName: "V1-0018_.00000000.mp4",
        },
        {
          name: "Youtube_订阅点赞分享",
          videoName: "V1-0019_.00000000.mp4",
        },
        {
          name: "Youtube_订阅点赞分享_精简",
          videoName: "V1-0020_.00000000.mp4",
        },
        {
          name: "哔哩哔哩_一键三连",
          videoName: "V1-0021_.00000000.mp4",
        },
        {
          name: "哔哩哔哩_一键三连_精简",
          videoName: "V1-0022_.00000000.mp4",
        },
        {
          name: "哔哩哔哩_圆形",
          videoName: "V1-0023_.00000000.mp4",
        },
        {
          name: "哔哩哔哩_扁平",
          videoName: "V1-0024_.00000000.mp4",
        },
        {
          name: "哔哩哔哩_方形",
          videoName: "V1-0025_.00000000.mp4",
        },
        {
          name: "小红书_圆形",
          videoName: "V1-0026_.00000000.mp4",
        },
        {
          name: "小红书_扁平",
          videoName: "V1-0027_.00000000.mp4",
        },
        {
          name: "小红书_方形",
          videoName: "V1-0028_.00000000.mp4",
        },
        {
          name: "微信_圆形",
          videoName: "V1-0029_.00000000.mp4",
        },
        {
          name: "微信_扁平",
          videoName: "V1-0030_.00000000.mp4",
        },
        {
          name: "微信_方形",
          videoName: "V1-0031_.00000000.mp4",
        },
        {
          name: "微博_圆形",
          videoName: "V1-0032_.00000000.mp4",
        },
        {
          name: "微博_扁平",
          videoName: "V1-0033_.00000000.mp4",
        },
        {
          name: "微博_方形",
          videoName: "V1-0034_.00000000.mp4",
        },
        {
          name: "快手_界面_覆盖",
          videoName: "V1-0035_.00000000.mp4",
        },
        {
          name: "抖音_圆形",
          videoName: "V1-0036_.00000000.mp4",
        },
        {
          name: "抖音_头像_分享_1",
          videoName: "V1-0037_.00000000.mp4",
        },
        {
          name: "抖音_头像_分享_2",
          videoName: "V1-0038_.00000000.mp4",
        },
        {
          name: "抖音_扁平",
          videoName: "V1-0039_.00000000.mp4",
        },
        {
          name: "抖音_方形",
          videoName: "V1-0040_.00000000.mp4",
        },
        {
          name: "抖音_界面_覆盖",
          videoName: "V1-0041_.00000000.mp4",
        },
        {
          name: "淘宝_圆形",
          videoName: "V1-0042_.00000000.mp4",
        },
        {
          name: "淘宝_扁平",
          videoName: "V1-0043_.00000000.mp4",
        },
        {
          name: "淘宝_方形",
          videoName: "V1-0044_.00000000.mp4",
        },
        {
          name: "聊天_气泡_右侧",
          videoName: "V1-0045_.00000000.mp4",
        },
        {
          name: "聊天_气泡_左侧",
          videoName: "V1-0046_.00000000.mp4",
        },
        {
          name: "自定义_圆形",
          videoName: "V1-0047_.00000000.mp4",
        },
        {
          name: "自定义_扁平",
          videoName: "V1-0048_.00000000.mp4",
        },
        {
          name: "自定义_方形",
          videoName: "V1-0049_.00000000.mp4",
        },
        {
          name: "自定义_点赞分享_精简",
          videoName: "V1-0050_.00000000.mp4",
        },
        {
          name: "西瓜视频_圆形",
          videoName: "V1-0051_.00000000.mp4",
        },
        {
          name: "西瓜视频_扁平",
          videoName: "V1-0052_.00000000.mp4",
        },
        {
          name: "西瓜视频_方形",
          videoName: "V1-0053_.00000000.mp4",
        },
        {
          name: "西瓜视频_点赞分享",
          videoName: "V1-0054_.00000000.mp4",
        },
        {
          name: "西瓜视频_点赞分享_精简",
          videoName: "V1-0055_.00000000.mp4",
        },
      ],
      Glitch_Lower_Thirds_30FPS: [
        {
          name: "故障 文字 01",
          videoName: "V1-0001_.00000000.mp4",
        },
        {
          name: "故障 文字 02",
          videoName: "V1-0002_.00000000.mp4",
        },
        {
          name: "故障 文字 03",
          videoName: "V1-0003_.00000000.mp4",
        },
        {
          name: "故障 文字 04",
          videoName: "V1-0004_.00000000.mp4",
        },
        {
          name: "故障 文字 05",
          videoName: "V1-0005_.00000000.mp4",
        },
        {
          name: "故障 方块 01",
          videoName: "V1-0006_.00000000.mp4",
        },
        {
          name: "故障 方块 02",
          videoName: "V1-0007_.00000000.mp4",
        },
        {
          name: "故障 方块 03",
          videoName: "V1-0008_.00000000.mp4",
        },
        {
          name: "故障 方块 04",
          videoName: "V1-0009_.00000000.mp4",
        },
        {
          name: "故障 方块 05",
          videoName: "V1-0010_.00000000.mp4",
        },
        {
          name: "故障 线条 01",
          videoName: "V1-0011_.00000000.mp4",
        },
        {
          name: "故障 线条 02",
          videoName: "V1-0012_.00000000.mp4",
        },
        {
          name: "故障 线条 03",
          videoName: "V1-0013_.00000000.mp4",
        },
        {
          name: "故障 线条 04",
          videoName: "V1-0014_.00000000.mp4",
        },
        {
          name: "故障 线条 05",
          videoName: "V1-0015_.00000000.mp4",
        },
      ],
      Glitch_Lower_Thirds_60FPS: [
        {
          name: "故障 文字 01 高帧率",
          videoName: "V1-0001_.00000000.mp4",
        },
        {
          name: "故障 文字 02 高帧率",
          videoName: "V1-0002_.00000000.mp4",
        },
        {
          name: "故障 文字 03 高帧率",
          videoName: "V1-0003_.00000000.mp4",
        },
        {
          name: "故障 文字 04 高帧率",
          videoName: "V1-0004_.00000000.mp4",
        },
        {
          name: "故障 文字 05 高帧率",
          videoName: "V1-0005_.00000000.mp4",
        },
        {
          name: "故障 方块 01 高帧率",
          videoName: "V1-0006_.00000000.mp4",
        },
        {
          name: "故障 方块 02 高帧率",
          videoName: "V1-0007_.00000000.mp4",
        },
        {
          name: "故障 方块 03 高帧率",
          videoName: "V1-0008_.00000000.mp4",
        },
        {
          name: "故障 方块 04 高帧率",
          videoName: "V1-0009_.00000000.mp4",
        },
        {
          name: "故障 方块 05 高帧率",
          videoName: "V1-0010_.00000000.mp4",
        },
        {
          name: "故障 线条 01 高帧率",
          videoName: "V1-0011_.00000000.mp4",
        },
        {
          name: "故障 线条 02 高帧率",
          videoName: "V1-0012_.00000000.mp4",
        },
        {
          name: "故障 线条 03 高帧率",
          videoName: "V1-0013_.00000000.mp4",
        },
        {
          name: "故障 线条 04 高帧率",
          videoName: "V1-0014_.00000000.mp4",
        },
        {
          name: "故障 线条 05 高帧率",
          videoName: "V1-0015_.00000000.mp4",
        },
      ],
      Innovation_Titles_Templates: [
        {
          name: "方块 两侧标题 01",
          videoName: "V1-0001_.00000000.mp4",
        },
        {
          name: "方块 两侧标题 02",
          videoName: "V1-0002_.00000000.mp4",
        },
        {
          name: "方块 两侧标题 03",
          videoName: "V1-0003_.00000000.mp4",
        },
        {
          name: "方块 单行标题 01",
          videoName: "V1-0004_.00000000.mp4",
        },
        {
          name: "方块 单行标题 02",
          videoName: "V1-0005_.00000000.mp4",
        },
        {
          name: "方块 单行标题 03",
          videoName: "V1-0006_.00000000.mp4",
        },
        {
          name: "方块 双行标题 01",
          videoName: "V1-0007_.00000000.mp4",
        },
        {
          name: "方块 双行标题 02",
          videoName: "V1-0008_.00000000.mp4",
        },
        {
          name: "方块 双行标题 03",
          videoName: "V1-0009_.00000000.mp4",
        },
        {
          name: "方块 双行标题 04",
          videoName: "V1-0010_.00000000.mp4",
        },
        {
          name: "方块 双行标题 05",
          videoName: "V1-0011_.00000000.mp4",
        },
        {
          name: "线段 合并 双行标题",
          videoName: "V1-0012_.00000000.mp4",
        },
        {
          name: "线段 居中分开 双行标题",
          videoName: "V1-0013_.00000000.mp4",
        },
        {
          name: "线段 展开 双行标题 01",
          videoName: "V1-0014_.00000000.mp4",
        },
        {
          name: "线段 展开 双行标题 02",
          videoName: "V1-0015_.00000000.mp4",
        },
        {
          name: "线段 展开 双行标题 03",
          videoName: "V1-0016_.00000000.mp4",
        },
        {
          name: "线段 展开 双行标题 04",
          videoName: "V1-0017_.00000000.mp4",
        },
        {
          name: "线段 滑动 单行标题 01",
          videoName: "V1-0018_.00000000.mp4",
        },
        {
          name: "线段 滑动 单行标题 02",
          videoName: "V1-0019_.00000000.mp4",
        },
        {
          name: "线段 滑动 双行标题",
          videoName: "V1-0020_.00000000.mp4",
        },
        {
          name: "线段 自旋 双行标题 01",
          videoName: "V1-0021_.00000000.mp4",
        },
        {
          name: "线段 自旋 双行标题 02",
          videoName: "V1-0022_.00000000.mp4",
        },
      ],
      Wedding_Titles_Template: [
        {
          name: "婚礼 标题 01",
          videoName: "V1-0001_.00000000.mp4",
        },
        {
          name: "婚礼 标题 02",
          videoName: "V1-0002_.00000000.mp4",
        },
        {
          name: "婚礼 标题 03",
          videoName: "V1-0003_.00000000.mp4",
        },
        {
          name: "婚礼 标题 04",
          videoName: "V1-0004_.00000000.mp4",
        },
        {
          name: "婚礼 标题 05",
          videoName: "V1-0005_.00000000.mp4",
        },
        {
          name: "婚礼 标题 06",
          videoName: "V1-0006_.00000000.mp4",
        },
        {
          name: "婚礼 标题 07",
          videoName: "V1-0007_.00000000.mp4",
        },
        {
          name: "婚礼 标题 08",
          videoName: "V1-0008_.00000000.mp4",
        },
        {
          name: "婚礼 标题 09",
          videoName: "V1-0009_.00000000.mp4",
        },
        {
          name: "婚礼 标题 10",
          videoName: "V1-0010_.00000000.mp4",
        },
        {
          name: "婚礼 标题 11",
          videoName: "V1-0011_.00000000.mp4",
        },
        {
          name: "婚礼 标题 12",
          videoName: "V1-0012_.00000000.mp4",
        },
        {
          name: "婚礼 标题 13",
          videoName: "V1-0013_.00000000.mp4",
        },
        {
          name: "婚礼 标题 14",
          videoName: "V1-0014_.00000000.mp4",
        },
        {
          name: "婚礼 标题 15",
          videoName: "V1-0015_.00000000.mp4",
        },
        {
          name: "婚礼 标题 16",
          videoName: "V1-0016_.00000000.mp4",
        },
        {
          name: "婚礼 标题 17",
          videoName: "V1-0017_.00000000.mp4",
        },
        {
          name: "婚礼 标题 18",
          videoName: "V1-0018_.00000000.mp4",
        },
        {
          name: "婚礼 标题 19",
          videoName: "V1-0019_.00000000.mp4",
        },
        {
          name: "婚礼 标题 20",
          videoName: "V1-0020_.00000000.mp4",
        },
        {
          name: "婚礼 标题 21",
          videoName: "V1-0021_.00000000.mp4",
        },
        {
          name: "婚礼 标题 22",
          videoName: "V1-0022_.00000000.mp4",
        },
        {
          name: "婚礼 标题 23",
          videoName: "V1-0023_.00000000.mp4",
        },
        {
          name: "婚礼 标题 24",
          videoName: "V1-0024_.00000000.mp4",
        },
      ],
      Brush_Paint_Titles_Template: [
        {
          name: "油彩 01",
          videoName: "V1-0001_.00000000.mp4",
        },
        {
          name: "油彩 02",
          videoName: "V1-0002_.00000000.mp4",
        },
        {
          name: "油彩 03",
          videoName: "V1-0003_.00000000.mp4",
        },
        {
          name: "油彩 04",
          videoName: "V1-0004_.00000000.mp4",
        },
        {
          name: "油彩 05",
          videoName: "V1-0005_.00000000.mp4",
        },
        {
          name: "油彩 06",
          videoName: "V1-0006_.00000000.mp4",
        },
        {
          name: "油彩渐变 01",
          videoName: "V1-0007_.00000000.mp4",
        },
        {
          name: "油彩渐变 02",
          videoName: "V1-0008_.00000000.mp4",
        },
        {
          name: "油彩渐变 03",
          videoName: "V1-0009_.00000000.mp4",
        },
        {
          name: "油彩渐变 04",
          videoName: "V1-0010_.00000000.mp4",
        },
        {
          name: "油彩渐变 05",
          videoName: "V1-0011_.00000000.mp4",
        },
        {
          name: "油彩渐变 06",
          videoName: "V1-0012_.00000000.mp4",
        },
        {
          name: "油彩渐变 07",
          videoName: "V1-0013_.00000000.mp4",
        },
        {
          name: "油彩渐变 08",
          videoName: "V1-0014_.00000000.mp4",
        },
        {
          name: "油彩渐变 09",
          videoName: "V1-0015_.00000000.mp4",
        },
        {
          name: "笔刷 01",
          videoName: "V1-0016_.00000000.mp4",
        },
        {
          name: "笔刷 02",
          videoName: "V1-0017_.00000000.mp4",
        },
        {
          name: "笔刷 03",
          videoName: "V1-0018_.00000000.mp4",
        },
        {
          name: "笔刷 04",
          videoName: "V1-0019_.00000000.mp4",
        },
        {
          name: "笔刷 05",
          videoName: "V1-0020_.00000000.mp4",
        },
        {
          name: "笔刷 06",
          videoName: "V1-0021_.00000000.mp4",
        },
        {
          name: "笔刷 07",
          videoName: "V1-0022_.00000000.mp4",
        },
        {
          name: "笔刷 08",
          videoName: "V1-0023_.00000000.mp4",
        },
        {
          name: "笔刷 09",
          videoName: "V1-0024_.00000000.mp4",
        },
        {
          name: "笔刷 10",
          videoName: "V1-0025_.00000000.mp4",
        },
        {
          name: "笔刷 11",
          videoName: "V1-0026_.00000000.mp4",
        },
        {
          name: "笔刷 盒子 01",
          videoName: "V1-0027_.00000000.mp4",
        },
        {
          name: "笔刷 盒子 02",
          videoName: "V1-0028_.00000000.mp4",
        },
      ],
      Cinematic_Titles_Template: [
        {
          name: "老电视",
          videoName: "V2-0020_OLD-TV.mp4",
          classify: "特效",
          newTemplate: true
        },
        {
          name: "电影标题 01",
          videoName: "V2-0001_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },
        {
          name: "电影标题 02",
          videoName: "V2-0002_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },
        {
          name: "电影标题 03",
          videoName: "V2-0003_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },
        {
          name: "电影标题 04",
          videoName: "V2-0004_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },
        {
          name: "电影标题 05",
          videoName: "V2-0005_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },
        {
          name: "电影标题 06",
          videoName: "V2-0006_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },
        {
          name: "电影标题 07",
          videoName: "V2-0007_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },
        {
          name: "电影标题 08",
          videoName: "V2-0008_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },
        {
          name: "电影标题 09",
          videoName: "V2-0009_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },
        {
          name: "电影标题 10",
          videoName: "V2-0010_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },
        {
          name: "电影标题 11",
          videoName: "V2-0011_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },
        {
          name: "电影标题 12",
          videoName: "V2-0012_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },
        {
          name: "电影标题 13",
          videoName: "V2-0013_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },
        {
          name: "电影标题 14",
          videoName: "V2-0014_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },
        {
          name: "电影标题 15",
          videoName: "V2-0015_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },
        {
          name: "电影标题 16",
          videoName: "V2-0016_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },
        {
          name: "电影标题 17",
          videoName: "V2-0017_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },
        {
          name: "电影标题 18",
          videoName: "V2-0018_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },
        {
          name: "电影标题 19",
          videoName: "V2-0019_.00000000.mp4",
          classify: "标题-竖排",
          newTemplate: true
        },


        {
          name: "电影标题 01",
          videoName: "V1-0001_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 02",
          videoName: "V1-0002_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 03",
          videoName: "V1-0003_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 04",
          videoName: "V1-0004_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 05",
          videoName: "V1-0005_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 06",
          videoName: "V1-0006_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 07",
          videoName: "V1-0007_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 08",
          videoName: "V1-0008_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 09",
          videoName: "V1-0009_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 10",
          videoName: "V1-0010_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 11",
          videoName: "V1-0011_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 12",
          videoName: "V1-0012_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 13",
          videoName: "V1-0013_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 14",
          videoName: "V1-0014_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 15",
          videoName: "V1-0015_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 16",
          videoName: "V1-0016_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 17",
          videoName: "V1-0017_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 18",
          videoName: "V1-0018_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 19",
          videoName: "V1-0019_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 20",
          videoName: "V1-0020_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 21",
          videoName: "V1-0021_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 22",
          videoName: "V1-0022_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 23",
          videoName: "V1-0023_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 24",
          videoName: "V1-0024_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 25",
          videoName: "V1-0025_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 26",
          videoName: "V1-0026_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 27",
          videoName: "V1-0027_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 28",
          videoName: "V1-0028_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 29",
          videoName: "V1-0029_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 30",
          videoName: "V1-0030_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 31",
          videoName: "V1-0031_.00000000.mp4",
          classify: "标题-横排",
        },
        {
          name: "电影标题 32",
          videoName: "V1-0040_.00000000.mp4",
          classify: "标题-横排"
        },
        {
          name: "电影标题 33",
          videoName: "V1-0041_.00000000.mp4",
          classify: "标题-横排"
        },
        {
          name: "暗角",
          videoName: "V1-0032_.00000000.mp4",
          classify: "特效",
        },
        {
          name: "模糊",
          videoName: "V1-0033_.00000000.mp4",
          classify: "特效",
        },
        {
          name: "电影胶片 01",
          videoName: "V1-0034_.00000000.mp4",
          classify: "特效",
        },
        {
          name: "电影胶片 02",
          videoName: "V1-0035_.00000000.mp4",
          classify: "特效",
        },
        {
          name: "电影胶片 03",
          videoName: "V1-0036_.00000000.mp4",
          classify: "特效",
        },
        {
          name: "胶片颗粒",
          videoName: "V1-0037_.00000000.mp4",
          classify: "特效",
        },
        {
          name: "脏胶片",
          videoName: "V1-0038_.00000000.mp4",
          classify: "特效",
        },
        {
          name: "闪烁",
          videoName: "V1-0039_.00000000.mp4",
          classify: "特效",
        },
      ],
      Creative_Titles_Templates: [
        {
          name: "创意_企业_01",
          videoName: "V1-0001_.00000000.mp4",
        },
        {
          name: "创意_企业_02",
          videoName: "V1-0002_.00000000.mp4",
        },
        {
          name: "创意_企业_03",
          videoName: "V1-0003_.00000000.mp4",
        },
        {
          name: "创意_企业_04",
          videoName: "V1-0004_.00000000.mp4",
        },
        {
          name: "创意_企业_05",
          videoName: "V1-0005_.00000000.mp4",
        },
        {
          name: "创意_企业_06",
          videoName: "V1-0006_.00000000.mp4",
        },
        {
          name: "创意_企业_07",
          videoName: "V1-0007_.00000000.mp4",
        },
        {
          name: "创意_企业_08",
          videoName: "V1-0008_.00000000.mp4",
        },
        {
          name: "创意_企业_09",
          videoName: "V1-0009_.00000000.mp4",
        },
        {
          name: "创意_企业_10",
          videoName: "V1-0010_.00000000.mp4",
        },
        {
          name: "创意_时尚_01",
          videoName: "V1-0011_.00000000.mp4",
        },
        {
          name: "创意_时尚_02",
          videoName: "V1-0012_.00000000.mp4",
        },
        {
          name: "创意_时尚_03",
          videoName: "V1-0013_.00000000.mp4",
        },
        {
          name: "创意_时尚_04",
          videoName: "V1-0014_.00000000.mp4",
        },
        {
          name: "创意_时尚_05",
          videoName: "V1-0015_.00000000.mp4",
        },
        {
          name: "创意_时尚_06",
          videoName: "V1-0016_.00000000.mp4",
        },
        {
          name: "创意_时尚_07",
          videoName: "V1-0017_.00000000.mp4",
        },
        {
          name: "创意_时尚_08",
          videoName: "V1-0018_.00000000.mp4",
        },
        {
          name: "创意_时尚_09",
          videoName: "V1-0019_.00000000.mp4",
        },
        {
          name: "创意_时尚_10",
          videoName: "V1-0020_.00000000.mp4",
        },
        {
          name: "创意_简约_01",
          videoName: "V1-0021_.00000000.mp4",
        },
        {
          name: "创意_简约_02",
          videoName: "V1-0022_.00000000.mp4",
        },
        {
          name: "创意_简约_03",
          videoName: "V1-0023_.00000000.mp4",
        },
        {
          name: "创意_简约_04",
          videoName: "V1-0024_.00000000.mp4",
        },
        {
          name: "创意_简约_05",
          videoName: "V1-0025_.00000000.mp4",
        },
        {
          name: "创意_简约_06",
          videoName: "V1-0026_.00000000.mp4",
        },
        {
          name: "创意_简约_07",
          videoName: "V1-0027_.00000000.mp4",
        },
        {
          name: "创意_简约_08",
          videoName: "V1-0028_.00000000.mp4",
        },
        {
          name: "创意_简约_09",
          videoName: "V1-0029_.00000000.mp4",
        },
        {
          name: "创意_简约_10",
          videoName: "V1-0030_.00000000.mp4",
        },
      ],
      Variety_Show_Text_Templates: [
        {
          name: "字_云飘飘",
          videoName: "V1-0001_.00000000.mp4",
        },
        {
          name: "字_卡片_1",
          videoName: "V1-0002_.00000000.mp4",
        },
        {
          name: "字_卡片_2",
          videoName: "V1-0003_.00000000.mp4",
        },
        {
          name: "字_卡片_3",
          videoName: "V1-0004_.00000000.mp4",
        },
        {
          name: "字_呼喊_1",
          videoName: "V1-0005_.00000000.mp4",
        },
        {
          name: "字_呼喊_2",
          videoName: "V1-0006_.00000000.mp4",
        },
        {
          name: "字_呼喊_3",
          videoName: "V1-0007_.00000000.mp4",
        },
        {
          name: "字_哈哈",
          videoName: "V1-0008_.00000000.mp4",
        },
        {
          name: "字_哭泣",
          videoName: "V1-0009_.00000000.mp4",
        },
        {
          name: "字_奥力给",
          videoName: "V1-0010_.00000000.mp4",
        },
        {
          name: "字_心碎",
          videoName: "V1-0011_.00000000.mp4",
        },
        {
          name: "字_心跳_1",
          videoName: "V1-0012_.00000000.mp4",
        },
        {
          name: "字_心跳_2",
          videoName: "V1-0013_.00000000.mp4",
        },
        {
          name: "字_惊讶_1",
          videoName: "V1-0014_.00000000.mp4",
        },
        {
          name: "字_惊讶_2",
          videoName: "V1-0015_.00000000.mp4",
        },
        {
          name: "字_惊讶_3",
          videoName: "V1-0016_.00000000.mp4",
        },
        {
          name: "字_样式_1_黄",
          videoName: "V1-0017_.00000000.mp4",
        },
        {
          name: "字_样式_2_蓝",
          videoName: "V1-0018_.00000000.mp4",
        },
        {
          name: "字_样式_3_粉",
          videoName: "V1-0019_.00000000.mp4",
        },
        {
          name: "字_样式_3_紫",
          videoName: "V1-0020_.00000000.mp4",
        },
        {
          name: "字_样式_4_粉",
          videoName: "V1-0021_.00000000.mp4",
        },
        {
          name: "字_样式_4_赛博朋克",
          videoName: "V1-0022_.00000000.mp4",
        },
        {
          name: "字_样式_5_红",
          videoName: "V1-0023_.00000000.mp4",
        },
        {
          name: "字_样式_5_绿",
          videoName: "V1-0024_.00000000.mp4",
        },
        {
          name: "字_样式_5_黄",
          videoName: "V1-0025_.00000000.mp4",
        },
        {
          name: "字_样式_6_夕阳",
          videoName: "V1-0026_.00000000.mp4",
        },
        {
          name: "字_样式_6_蓝",
          videoName: "V1-0027_.00000000.mp4",
        },
        {
          name: "字_样式_7_粉笔",
          videoName: "V1-0028_.00000000.mp4",
        },
        {
          name: "字_气泡_1",
          videoName: "V1-0029_.00000000.mp4",
        },
        {
          name: "字_气泡_2",
          videoName: "V1-0030_.00000000.mp4",
        },
        {
          name: "字_气泡_3",
          videoName: "V1-0031_.00000000.mp4",
        },
        {
          name: "字_气泡_4",
          videoName: "V1-0032_.00000000.mp4",
        },
        {
          name: "字_气泡_5",
          videoName: "V1-0033_.00000000.mp4",
        },
        {
          name: "字_气泡_6",
          videoName: "V1-0034_.00000000.mp4",
        },
        {
          name: "字_气泡_7",
          videoName: "V1-0035_.00000000.mp4",
        },
        {
          name: "字_漫画_气泡_01",
          videoName: "V1-0036_.00000000.mp4",
        },
        {
          name: "字_漫画_气泡_02",
          videoName: "V1-0037_.00000000.mp4",
        },
        {
          name: "字_漫画_气泡_03",
          videoName: "V1-0038_.00000000.mp4",
        },
        {
          name: "字_漫画_气泡_04",
          videoName: "V1-0039_.00000000.mp4",
        },
        {
          name: "字_漫画_气泡_05",
          videoName: "V1-0040_.00000000.mp4",
        },
        {
          name: "字_漫画_气泡_06",
          videoName: "V1-0041_.00000000.mp4",
        },
        {
          name: "字_漫画_气泡_07",
          videoName: "V1-0042_.00000000.mp4",
        },
        {
          name: "字_漫画_气泡_08",
          videoName: "V1-0043_.00000000.mp4",
        },
        {
          name: "字_漫画_气泡_09",
          videoName: "V1-0044_.00000000.mp4",
        },
        {
          name: "字_漫画_气泡_10",
          videoName: "V1-0045_.00000000.mp4",
        },
        {
          name: "字_生气",
          videoName: "V1-0046_.00000000.mp4",
        },
        {
          name: "字_箭头_1",
          videoName: "V1-0047_.00000000.mp4",
        },
        {
          name: "字_闪亮",
          videoName: "V1-0048_.00000000.mp4",
        },
        {
          name: "字_饮茶时刻",
          videoName: "V1-0049_.00000000.mp4",
        },
        {
          name: "花_小锤锤",
          videoName: "V1-0050_.00000000.mp4",
        },
        {
          name: "花_尴尬鸟",
          videoName: "V1-0051_.00000000.mp4",
        },
        {
          name: "花_惆怅",
          videoName: "V1-0052_.00000000.mp4",
        },
        {
          name: "花_惊叹号",
          videoName: "V1-0053_.00000000.mp4",
        },
        {
          name: "花_惊讶",
          videoName: "V1-0054_.00000000.mp4",
        },
        {
          name: "花_桃心",
          videoName: "V1-0055_.00000000.mp4",
        },
        {
          name: "花_气",
          videoName: "V1-0056_.00000000.mp4",
        },
        {
          name: "花_水滴",
          videoName: "V1-0057_.00000000.mp4",
        },
        {
          name: "花_漫画_叹号",
          videoName: "V1-0058_.00000000.mp4",
        },
        {
          name: "花_漫画_箭头",
          videoName: "V1-0059_.00000000.mp4",
        },
        {
          name: "花_箭头_1",
          videoName: "V1-0060_.00000000.mp4",
        },
        {
          name: "花_闪亮",
          videoName: "V1-0061_.00000000.mp4",
        },
        {
          name: "花_闪电",
          videoName: "V1-0062_.00000000.mp4",
        },
        {
          name: "花_问号",
          videoName: "V1-0063_.00000000.mp4",
        },
        {
          name: "花_震惊",
          videoName: "V1-0064_.00000000.mp4",
        },
      ],
      VChart_Templates: [
        {
          name: "计数器",
          videoName: "V1-0035_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "电池 垂直",
          videoName: "V1-0030_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "电池 水平",
          videoName: "V1-0031_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "雷达图_六边形_圆形",
          videoName: "V1-0032_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "雷达图_六边形",
          videoName: "V1-0033_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "雷达图_五边形_圆形",
          videoName: "V1-0034_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "图示_折线图",
          videoName: "V1-0001_.00000000.mp4",
        },
        {
          name: "图示_柱状图",
          videoName: "V1-0002_.00000000.mp4",
        },
        {
          name: "图示_饼状图",
          videoName: "V1-0003_.00000000.mp4",
        },
        {
          name: "坐标系_X轴",
          videoName: "V1-0004_.00000000.mp4",
        },
        {
          name: "坐标系_X轴_文字",
          videoName: "V1-0005_.00000000.mp4",
        },
        {
          name: "坐标系_Y轴",
          videoName: "V1-0006_.00000000.mp4",
        },
        {
          name: "坐标系_Y轴_文字",
          videoName: "V1-0007_.00000000.mp4",
        },
        {
          name: "折线图_基础",
          videoName: "V1-0008_.00000000.mp4",
        },
        {
          name: "数字_滚动",
          videoName: "V1-0009_.00000000.mp4",
        },
        {
          name: "柱状图_单行_文字",
          videoName: "V1-0010_.00000000.mp4",
        },
        {
          name: "柱状图_横向",
          videoName: "V1-0011_.00000000.mp4",
        },
        {
          name: "柱状图_纵向",
          videoName: "V1-0012_.00000000.mp4",
        },
        {
          name: "柱状图_随机_单行_文字",
          videoName: "V1-0013_.00000000.mp4",
        },
        {
          name: "活动_3_圆圈",
          videoName: "V1-0014_.00000000.mp4",
        },
        {
          name: "活动_4_圆圈",
          videoName: "V1-0015_.00000000.mp4",
        },
        {
          name: "活动_5_圆圈",
          videoName: "V1-0016_.00000000.mp4",
        },
        {
          name: "百分比_圆圈",
          videoName: "V1-0017_.00000000.mp4",
        },
        {
          name: "百分比_横条",
          videoName: "V1-0018_.00000000.mp4",
        },
        {
          name: "百分比_波浪",
          videoName: "V1-0019_.00000000.mp4",
        },
        {
          name: "百分比_饼状",
          videoName: "V1-0020_.00000000.mp4",
        },
        {
          name: "翻转数字",
          videoName: "V1-0021_.00000000.mp4",
        },
        {
          name: "表",
          videoName: "V1-0022_.00000000.mp4",
        },
        {
          name: "计时器",
          videoName: "V1-0023_.00000000.mp4",
        },
        {
          name: "计时器_滚动",
          videoName: "V1-0024_.00000000.mp4",
        },
        {
          name: "雷达图_五边形",
          videoName: "V1-0025_.00000000.mp4",
        },
        {
          name: "雷达图_四边形",
          videoName: "V1-0026_.00000000.mp4",
        },
        {
          name: "雷达图_圆形",
          videoName: "V1-0027_.00000000.mp4",
        },
        {
          name: "饼状图_基础",
          videoName: "V1-0028_.00000000.mp4",
        },
        {
          name: "饼状图_甜甜圈",
          videoName: "V1-0029_.00000000.mp4",
        },
      ],
      MG_Animation_Templates: [
        {
          name: "角度",
          videoName: "V1-0069_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "角度_自定义",
          videoName: "V1-0070_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "背景_文字_循环",
          videoName: "V1-0071_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "边框_文字滚动_横屏",
          videoName: "V1-0072_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "边框_文字滚动_竖屏",
          videoName: "V1-0073_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "鼠标",
          videoName: "V1-0074_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "选区_区域_圆角",
          videoName: "V1-0075_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "选区_流动_圆角",
          videoName: "V1-0076_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "选区_流动区域_圆角",
          videoName: "V1-0077_.00000000.mp4",
          newTemplate: true
        },

        {
          name: "对钩",
          videoName: "V1-0066_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "错误",
          videoName: "V1-0067_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "波浪线",
          videoName: "V1-0068_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "加载_圆_1",
          videoName: "V1-0001_.00000000.mp4",
        },
        {
          name: "加载_圆_2",
          videoName: "V1-0002_.00000000.mp4",
        },
        {
          name: "加载_圆_3",
          videoName: "V1-0003_.00000000.mp4",
        },
        {
          name: "圆圈_基础",
          videoName: "V1-0004_.00000000.mp4",
        },
        {
          name: "圆圈_嵌套",
          videoName: "V1-0005_.00000000.mp4",
        },
        {
          name: "圆圈_拖尾",
          videoName: "V1-0006_.00000000.mp4",
        },
        {
          name: "圆圈_焦点",
          videoName: "V1-0007_.00000000.mp4",
        },
        {
          name: "手绘_圆圈",
          videoName: "V1-0008_.00000000.mp4",
        },
        {
          name: "手绘_对勾",
          videoName: "V1-0009_.00000000.mp4",
        },
        {
          name: "手绘_箭头_1",
          videoName: "V1-0010_.00000000.mp4",
        },
        {
          name: "手绘_箭头_2",
          videoName: "V1-0011_.00000000.mp4",
        },
        {
          name: "手绘_线段_1",
          videoName: "V1-0012_.00000000.mp4",
        },
        {
          name: "手绘_线段_2",
          videoName: "V1-0013_.00000000.mp4",
        },
        {
          name: "手绘_线段_3",
          videoName: "V1-0014_.00000000.mp4",
        },
        {
          name: "手绘_线段_4",
          videoName: "V1-0015_.00000000.mp4",
        },
        {
          name: "手绘_错误",
          videoName: "V1-0016_.00000000.mp4",
        },
        {
          name: "提示标签",
          videoName: "V1-0017_.00000000.mp4",
        },
        {
          name: "标尺_基础_垂直",
          videoName: "V1-0018_.00000000.mp4",
        },
        {
          name: "标尺_基础_水平",
          videoName: "V1-0019_.00000000.mp4",
        },
        {
          name: "标尺_箭头_垂直",
          videoName: "V1-0020_.00000000.mp4",
        },
        {
          name: "标尺_箭头_水平",
          videoName: "V1-0021_.00000000.mp4",
        },
        {
          name: "标尺_阴影线_垂直",
          videoName: "V1-0022_.00000000.mp4",
        },
        {
          name: "标尺_阴影线_水平",
          videoName: "V1-0023_.00000000.mp4",
        },
        {
          name: "波浪_三角形",
          videoName: "V1-0024_.00000000.mp4",
        },
        {
          name: "波浪_基础",
          videoName: "V1-0025_.00000000.mp4",
        },
        {
          name: "波浪_实心",
          videoName: "V1-0026_.00000000.mp4",
        },
        {
          name: "波浪_涟漪",
          videoName: "V1-0027_.00000000.mp4",
        },
        {
          name: "波浪_矩形",
          videoName: "V1-0028_.00000000.mp4",
        },
        {
          name: "波浪_空心",
          videoName: "V1-0029_.00000000.mp4",
        },
        {
          name: "烟花_基础",
          videoName: "V1-0030_.00000000.mp4",
        },
        {
          name: "烟花_嵌套",
          videoName: "V1-0031_.00000000.mp4",
        },
        {
          name: "烟花_嵌套_旋转_逆时针",
          videoName: "V1-0032_.00000000.mp4",
        },
        {
          name: "烟花_嵌套_旋转_顺时针",
          videoName: "V1-0033_.00000000.mp4",
        },
        {
          name: "烟花_逆时针",
          videoName: "V1-0034_.00000000.mp4",
        },
        {
          name: "烟花_顺时针",
          videoName: "V1-0035_.00000000.mp4",
        },
        {
          name: "箭头",
          videoName: "V1-0036_.00000000.mp4",
        },
        {
          name: "箭头_圆圈_自定义",
          videoName: "V1-0037_.00000000.mp4",
        },
        {
          name: "箭头_滑动",
          videoName: "V1-0038_.00000000.mp4",
        },
        {
          name: "箭头_矩形_自定义",
          videoName: "V1-0039_.00000000.mp4",
        },
        {
          name: "箭头_线段_自定义",
          videoName: "V1-0040_.00000000.mp4",
        },
        {
          name: "绽放_三角形",
          videoName: "V1-0041_.00000000.mp4",
        },
        {
          name: "绽放_矩形",
          videoName: "V1-0042_.00000000.mp4",
        },
        {
          name: "背景_图片_循环",
          videoName: "V1-0043_.00000000.mp4",
        },
        {
          name: "背景_星光_01",
          videoName: "V1-0044_.00000000.mp4",
        },
        {
          name: "背景_星光_02",
          videoName: "V1-0045_.00000000.mp4",
        },
        {
          name: "背景_星光_03",
          videoName: "V1-0046_.00000000.mp4",
        },
        {
          name: "背景_星轨",
          videoName: "V1-0047_.00000000.mp4",
        },
        {
          name: "背景_波浪_双色",
          videoName: "V1-0048_.00000000.mp4",
        },
        {
          name: "背景_矩形",
          videoName: "V1-0049_.00000000.mp4",
        },
        {
          name: "背景_线条",
          videoName: "V1-0050_.00000000.mp4",
        },
        {
          name: "背景_网格",
          videoName: "V1-0051_.00000000.mp4",
        },
        {
          name: "背景_网点",
          videoName: "V1-0052_.00000000.mp4",
        },
        {
          name: "边框_文字滚动_横屏",
          videoName: "V1-0053_.00000000.mp4",
        },
        {
          name: "边框_文字滚动_竖屏",
          videoName: "V1-0054_.00000000.mp4",
        },
        {
          name: "选区_区域",
          videoName: "V1-0055_.00000000.mp4",
        },
        {
          name: "选区_无边框",
          videoName: "V1-0056_.00000000.mp4",
        },
        {
          name: "选区_流动",
          videoName: "V1-0057_.00000000.mp4",
        },
        {
          name: "选区_焦点",
          videoName: "V1-0058_.00000000.mp4",
        },
        {
          name: "选区_虚线",
          videoName: "V1-0059_.00000000.mp4",
        },
        {
          name: "选区_虚线_圆形",
          videoName: "V1-0060_.00000000.mp4",
        },
        {
          name: "选区_闪烁",
          videoName: "V1-0061_.00000000.mp4",
        },
        {
          name: "键盘_三个",
          videoName: "V1-0062_.00000000.mp4",
        },
        {
          name: "键盘_两个",
          videoName: "V1-0063_.00000000.mp4",
        },
        {
          name: "键盘_两个_切换",
          videoName: "V1-0064_.00000000.mp4",
        },
        {
          name: "键盘_单个",
          videoName: "V1-0065_.00000000.mp4",
        },
      ],
      AERO_Glass_Transitions: [
        {
          name: "上",
          videoName: "V1-0001_A1.mp4",
        },
        {
          name: "下",
          videoName: "V1-0002_A2.mp4",
        },
        {
          name: "右",
          videoName: "V1-0003_A3.mp4",
        },
        {
          name: "右上",
          videoName: "V1-0004_A4.mp4",
        },
        {
          name: "右下",
          videoName: "V1-0005_A5.mp4",
        },
        {
          name: "右斜_展开",
          videoName: "V1-0006_A6.mp4",
        },
        {
          name: "圆圈_扩大",
          videoName: "V1-0007_A7.mp4",
        },
        {
          name: "圆圈_缩小",
          videoName: "V1-0008_A8.mp4",
        },
        {
          name: "垂直_展开",
          videoName: "V1-0009_A9.mp4",
        },
        {
          name: "垂直_收缩",
          videoName: "V1-0010_A10.mp4",
        },
        {
          name: "左",
          videoName: "V1-0011_A11.mp4",
        },
        {
          name: "左上",
          videoName: "V1-0012_A12.mp4",
        },
        {
          name: "左下",
          videoName: "V1-0013_A13.mp4",
        },
        {
          name: "左斜_展开",
          videoName: "V1-0014_A14.mp4",
        },
        {
          name: "旋转_上",
          videoName: "V1-0015_A15.mp4",
        },
        {
          name: "旋转_下",
          videoName: "V1-0016_A16.mp4",
        },
        {
          name: "旋转_右",
          videoName: "V1-0017_A17.mp4",
        },
        {
          name: "旋转_左",
          videoName: "V1-0018_A18.mp4",
        },
        {
          name: "水平_展开",
          videoName: "V1-0019_A19.mp4",
        },
        {
          name: "水平_收缩",
          videoName: "V1-0020_A20.mp4",
        },
        {
          name: "箭头_上",
          videoName: "V1-0021_A21.mp4",
        },
        {
          name: "箭头_下",
          videoName: "V1-0022_A22.mp4",
        },
      ],
      Rainbow_Transition: [
        {
          name: "展开",
          videoName: "V1-0001_C1.mp4",
        },
        {
          name: "展开 圆形",
          videoName: "V1-0002_C2.mp4",
        },
        {
          name: "彩虹条",
          videoName: "V1-0003_C3.mp4",
        },
        {
          name: "折叠",
          videoName: "V1-0004_C4.mp4",
        },
        {
          name: "折叠 圆形",
          videoName: "V1-0005_C5.mp4",
        },
        {
          name: "折扇",
          videoName: "V1-0006_C6.mp4",
        },
        {
          name: "折扇 圆形",
          videoName: "V1-0007_C7.mp4",
        },
        {
          name: "滑动 百叶窗",
          videoName: "V1-0008_C8.mp4",
        },
        {
          name: "滑动 阶梯",
          videoName: "V1-0009_C9.mp4",
        },
        {
          name: "穿越",
          videoName: "V1-0010_C10.mp4",
        },
        {
          name: "穿越 角度",
          videoName: "V1-0011_C11.mp4",
        },
      ],
      Split_Screen_Templates: [
        {
          name:"对角线 1x2",
          videoName: "V1-0036_对角线1x2.mp4",
          newTemplate: true
        },
        {
          name:"斜切 1x2",
          videoName: "V1-0037_斜切1x2.mp4",
          newTemplate: true
        },
        {
          name:"垂直 Y",
          videoName: "V1-0038_垂直Y.mp4",
          newTemplate: true
        },
        {
          name:"倾斜 3x1",
          videoName: "V1-0039_倾斜3x1.mp4",
          newTemplate: true
        },
        {
          name:"棋盘 2x2 自定义",
          videoName: "V1-0040_棋盘2x2自定义.mp4",
          newTemplate: true
        },
        {
          name:"田字 2x1x2 自定义",
          videoName: "V1-0041_田字2x1x2自定义.mp4",
          newTemplate: true
        },
        {
          name:"田字 1x2x2 自定义",
          videoName: "V1-0042_田字1x2x2自定义.mp4",
          newTemplate: true
        },
        {
          name:"田字 2x2x1 自定义",
          videoName: "V1-0043_田字2x2x1自定义.mp4",
          newTemplate: true
        },
        {
          name:"动态 1xN",
          videoName: "V1-0004_1xn.mp4",
          newTemplate: true
        },
        {
          name: "交叉 1x4",
          videoName: "V1-0001_C1.mp4",
        },
        {
          name: "倾斜 1x2 自定义",
          videoName: "V1-0002_C2.mp4",
        },
        {
          name: "倾斜 1x3",
          videoName: "V1-0003_C3.mp4",
        },
        {
          name: "倾斜 2x2",
          videoName: "V1-0004_C4.mp4",
        },
        {
          name: "倾斜 Vx3",
          videoName: "V1-0005_C5.mp4",
        },
        {
          name: "倾斜 Vx5",
          videoName: "V1-0006_C6.mp4",
        },
        {
          name: "垂直 1x2 自定义",
          videoName: "V1-0007_C7.mp4",
        },
        {
          name: "垂直 1x3",
          videoName: "V1-0008_C8.mp4",
        },
        {
          name: "垂直 1x4",
          videoName: "V1-0009_C9.mp4",
        },
        {
          name: "垂直 1x5",
          videoName: "V1-0010_C10.mp4",
        },
        {
          name: "垂直 1xM",
          videoName: "V1-0011_C11.mp4",
        },
        {
          name: "垂直 1xT 自定义",
          videoName: "V1-0012_C12.mp4",
        },
        {
          name: "垂直 Mx1",
          videoName: "V1-0013_C13.mp4",
        },
        {
          name: "垂直 Tx1 自定义",
          videoName: "V1-0014_C14.mp4",
        },
        {
          name: "垂直 Yx3",
          videoName: "V1-0015_C15.mp4",
        },
        {
          name: "居中 2x1x2",
          videoName: "V1-0016_C16.mp4",
        },
        {
          name: "水平 1x2 自定义",
          videoName: "V1-0017_C17.mp4",
        },
        {
          name: "水平 1x2x1",
          videoName: "V1-0018_C18.mp4",
        },
        {
          name: "水平 1x3",
          videoName: "V1-0019_C19.mp4",
        },
        {
          name: "水平 1x4",
          videoName: "V1-0020_C20.mp4",
        },
        {
          name: "水平 1x5",
          videoName: "V1-0021_C21.mp4",
        },
        {
          name: "水平 1xM",
          videoName: "V1-0022_C22.mp4",
        },
        {
          name: "水平 1xT 自定义",
          videoName: "V1-0023_C23.mp4",
        },
        {
          name: "水平 Mx1",
          videoName: "V1-0024_C24.mp4",
        },
        {
          name: "水平 Tx1 自定义",
          videoName: "V1-0025_C25.mp4",
        },
        {
          name: "滑动 百叶窗 垂直",
          videoName: "V1-0026_C26.mp4",
        },
        {
          name: "滑动 百叶窗 水平",
          videoName: "V1-0027_C27.mp4",
        },
        {
          name: "漩涡 1x5",
          videoName: "V1-0028_C28.mp4",
        },
        {
          name: "田字 2x1x2",
          videoName: "V1-0029_C29.mp4",
        },
        {
          name: "田字 2x2 自定义",
          videoName: "V1-0030_C30.mp4",
        },
        {
          name: "田字 2x3",
          videoName: "V1-0031_C31.mp4",
        },
        {
          name: "田字 3x2",
          videoName: "V1-0032_C32.mp4",
        },
        {
          name: "田字 3x3",
          videoName: "V1-0033_C33.mp4",
        },
        {
          name: "田字 4x2",
          videoName: "V1-0034_C34.mp4",
        },
      ],
      Standard_Transitions_V2: [
        {
          name: "像素网格 扩大",
          videoName: "V1-0001_C1.mp4",
          classify: "像素网格",
        },
        {
          name: "像素网格 擦除",
          videoName: "V1-0002_C2.mp4",
          classify: "像素网格",
        },
        {
          name: "像素网格 擦除 随机",
          videoName: "V1-0003_C3.mp4",
          classify: "像素网格",
        },
        {
          name: "像素网格 缩小",
          videoName: "V1-0004_C4.mp4",
          classify: "像素网格",
        },
        {
          name: "像素网格 随机",
          videoName: "V1-0005_C5.mp4",
          classify: "像素网格",
        },
        {
          name: "光斑 01",
          videoName: "V1-0006_C6.mp4",
          classify: "光斑",
        },
        {
          name: "光斑 02",
          videoName: "V1-0007_C7.mp4",
          classify: "光斑",
        },
        {
          name: "光斑 03",
          videoName: "V1-0008_C8.mp4",
          classify: "光斑",
        },
        {
          name: "光斑 04",
          videoName: "V1-0009_C9.mp4",
          classify: "光斑",
        },
        {
          name: "光斑 05",
          videoName: "V1-0010_C10.mp4",
          classify: "光斑",
        },
        {
          name: "光斑 06",
          videoName: "V1-0011_C11.mp4",
          classify: "光斑",
        },
        {
          name: "变焦 入 和 入",
          videoName: "V1-0012_C12.mp4",
          classify: "变焦",
        },
        {
          name: "变焦 入 和 出",
          videoName: "V1-0013_C13.mp4",
          classify: "变焦",
        },
        {
          name: "变焦 出 和 入",
          videoName: "V1-0014_C14.mp4",
          classify: "变焦",
        },
        {
          name: "变焦 出 和 出",
          videoName: "V1-0015_C15.mp4",
          classify: "变焦",
        },
        {
          name: "变焦 柔化",
          videoName: "V1-0016_C16.mp4",
          classify: "变焦",
        },
        {
          name: "形状 同心圆",
          videoName: "V1-0017_C17.mp4",
          classify: "形状",
        },
        {
          name: "形状 同心圆 四角",
          videoName: "V1-0018_C18.mp4",
          classify: "形状",
        },
        {
          name: "形状 流体",
          videoName: "V1-0019_C19.mp4",
          classify: "形状",
        },
        {
          name: "形状 线条 扫描线",
          videoName: "V1-0020_C20.mp4",
          classify: "形状",
        },
        {
          name: "形状 线条 滑动",
          videoName: "V1-0021_C21.mp4",
          classify: "形状",
        },
        {
          name: "形状 线条 百叶窗",
          videoName: "V1-0022_C22.mp4",
          classify: "形状",
        },
        {
          name: "影集 卡片滑动 垂直",
          videoName: "V1-0023_C23.mp4",
          classify: "影集",
        },
        {
          name: "影集 卡片滑动 水平",
          videoName: "V1-0024_C24.mp4",
          classify: "影集",
        },
        {
          name: "影集 文字滑动 垂直",
          videoName: "V1-0025_C25.mp4",
          classify: "影集",
        },
        {
          name: "影集 文字滑动 水平",
          videoName: "V1-0026_C26.mp4",
          classify: "影集",
        },
        {
          name: "抖动",
          videoName: "V1-0027_C27.mp4",
          classify: "抖动",
        },
        {
          name: "拉伸 垂直",
          videoName: "V1-0028_C28.mp4",
          classify: "位置",
        },
        {
          name: "拉伸 水平",
          videoName: "V1-0029_C29.mp4",
          classify: "位置",
        },
        {
          name: "推移",
          videoName: "V1-0030_C30.mp4",
          classify: "位置",
        },
        {
          name: "推移 间隙",
          videoName: "V1-0031_C31.mp4",
          classify: "位置",
        },
        {
          name: "故障 旧电视 01",
          videoName: "V1-0032_C32.mp4",
          classify: "故障",
        },
        {
          name: "故障 旧电视 02",
          videoName: "V1-0033_C33.mp4",
          classify: "故障",
        },
        {
          name: "故障 旧电视 03",
          videoName: "V1-0034_C34.mp4",
          classify: "故障",
        },
        {
          name: "故障 花屏 01",
          videoName: "V1-0035_C35.mp4",
          classify: "故障",
        },
        {
          name: "故障 花屏 02",
          videoName: "V1-0036_C36.mp4",
          classify: "故障",
        },
        {
          name: "故障 花屏 03",
          videoName: "V1-0037_C37.mp4",
          classify: "故障",
        },
        {
          name: "故障 花屏 04",
          videoName: "V1-0038_C38.mp4",
          classify: "故障",
        },
        {
          name: "故障 花屏 05",
          videoName: "V1-0039_C39.mp4",
          classify: "故障",
        },
        {
          name: "文字 变焦 穿越",
          videoName: "V1-0040_C40.mp4",
          classify: "变焦",
        },
        {
          name: "明度",
          videoName: "V1-0041_C41.mp4",
          classify: "亮度",
        },
        {
          name: "柔化 基础",
          videoName: "V1-0042_C42.mp4",
          classify: "柔化",
        },
        {
          name: "柔化 拉伸 垂直",
          videoName: "V1-0043_C43.mp4",
          classify: "柔化",
        },
        {
          name: "柔化 拉伸 水平",
          videoName: "V1-0044_C44.mp4",
          classify: "柔化",
        },
        {
          name: "柔化 擦除",
          videoName: "V1-0045_C45.mp4",
          classify: "柔化",
        },
        {
          name: "柔化 滑动",
          videoName: "V1-0046_C46.mp4",
          classify: "柔化",
        },
        {
          name: "柔化 眨眼",
          videoName: "V1-0047_C47.mp4",
          classify: "柔化",
        },
        {
          name: "滑动",
          videoName: "V1-0048_C48.mp4",
          classify: "位置",
        },
        {
          name: "滑动 景深",
          videoName: "V1-0049_C49.mp4",
          classify: "位置",
        },
        {
          name: "焦点 01",
          videoName: "V1-0050_C50.mp4",
          classify: "焦点",
        },
        {
          name: "焦点 02",
          videoName: "V1-0051_C51.mp4",
          classify: "焦点",
        },
        {
          name: "焦点 03",
          videoName: "V1-0052_C52.mp4",
          classify: "焦点",
        },
        {
          name: "焦点 04",
          videoName: "V1-0053_C53.mp4",
          classify: "焦点",
        },
        {
          name: "翻页",
          videoName: "V1-0054_C54.mp4",
          classify: "翻页",
        },
        {
          name: "自旋",
          videoName: "V1-0055_C55.mp4",
          classify: "自旋",
        },
        {
          name: "自旋 变焦",
          videoName: "V1-0056_C56.mp4",
          classify: "自旋",
        },
        {
          name: "自旋 景深",
          videoName: "V1-0057_C57.mp4",
          classify: "自旋",
        },
        {
          name: "遮罩 同心圆 1",
          videoName: "V1-0058_C58.mp4",
          classify: "遮罩",
        },
        {
          name: "遮罩 同心圆 2",
          videoName: "V1-0059_C59.mp4",
          classify: "遮罩",
        },
        {
          name: "遮罩 矩形 垂直",
          videoName: "V1-0060_C60.mp4",
          classify: "遮罩",
        },
        {
          name: "遮罩 矩形 水平",
          videoName: "V1-0061_C61.mp4",
          classify: "遮罩",
        },
        {
          name: "闪烁",
          videoName: "V1-0062_C62.mp4",
          classify: "闪烁",
        },
        {
          name: "闪烁 柔光",
          videoName: "V1-0063_C63.mp4",
          classify: "闪烁",
        },
        {
          name: "马赛克 四角",
          videoName: "V1-0064_C64.mp4",
          classify: "马赛克",
        },
        {
          name: "马赛克 圆形",
          videoName: "V1-0065_C65.mp4",
          classify: "马赛克",
        },
        {
          name: "马赛克 滑动",
          videoName: "V1-0066_C66.mp4",
          classify: "马赛克",
        },
      ],
      Image_Effect_Templates: [
        {
          name: "图像对比",
          videoName: "V4-0001.mp4",
          newTemplate: true
        },
        {
          name: "剪贴画",
          videoName: "V1-0025_C25.mp4",
          newTemplate: true
        },
        {
          name: "3D运镜",
          videoName: "V1-0001_C1.mp4",
        },
        {
          name: "划像 对比",
          videoName: "V1-0002_C2.mp4",
        },
        {
          name: "图像拉伸",
          videoName: "V1-0003_C3.mp4",
        },
        {
          name: "擦除 形状",
          videoName: "V1-0004_C4.mp4",
        },
        {
          name: "旋转 景深",
          videoName: "V1-0005_C5.mp4",
        },
        {
          name: "比例裁切",
          videoName: "V1-0006_C6.mp4",
        },
        {
          name: "滑动",
          videoName: "V1-0007_C7.mp4",
        },
        {
          name: "滑动 活泼",
          videoName: "V1-0008_C8.mp4",
        },
        {
          name: "滑动 路径",
          videoName: "V1-0009_C9.mp4",
        },
        {
          name: "神奇 变焦",
          videoName: "V1-0010_C10.mp4",
        },
        {
          name: "神奇 描边",
          videoName: "V1-0011_C11.mp4",
        },
        {
          name: "自旋",
          videoName: "V1-0012_C12.mp4",
        },
        {
          name: "自旋 活泼",
          videoName: "V1-0013_C13.mp4",
        },
        {
          name: "节奏 变焦",
          videoName: "V1-0014_C14.mp4",
        },
        {
          name: "节奏 变焦 RGB",
          videoName: "V1-0015_C15.mp4",
        },
        {
          name: "节奏 变焦 扩散",
          videoName: "V1-0016_C16.mp4",
        },
        {
          name: "节奏 晃动",
          videoName: "V1-0017_C17.mp4",
        },
        {
          name: "节奏 炫彩",
          videoName: "V1-0018_C18.mp4",
        },
        {
          name: "节奏 闪烁",
          videoName: "V1-0019_C19.mp4",
        },
        {
          name: "轻松遮罩",
          videoName: "V1-0020_C20.mp4",
        },
        {
          name: "边框",
          videoName: "V1-0021_C21.mp4",
        },
        {
          name: "阴影",
          videoName: "V1-0022_C22.mp4",
        },
        {
          name: "阴影 拉伸",
          videoName: "V1-0023_C23.mp4",
        },
        {
          name: "阴影 渐变",
          videoName: "V1-0024_C24.mp4",
        },
      ],
    },
  }),
});

export default useConstant;
